import { ValidationMessages } from '../validation-messages';

//Upload Image convert from Base64decode code to S3 URL
export class MyUploadAdapter {
    xhr: any;
    loader: any;
    constructor(loader) {
      this.loader = loader;
    }
  
    upload() {
      return this.loader.file
        .then(file => new Promise((resolve, reject) => {
          this._initRequest();
          this._initListeners(resolve, reject, file);
          this._sendRequest(file);
        }));
    }
  
    abort() {
      if (this.xhr) {
        this.xhr.abort();
      }
    }
  
    _initRequest() {
  
      var environmentt: string;
      var appUrl: string;
      environmentt = window.location.hostname;
      switch (environmentt) {
        case 'localhost':
          appUrl = 'http://localhost:8000/api';
          break;
        case (ValidationMessages.productionUrl):
          appUrl = 'https://' + ValidationMessages.productionAPUrl + '/api';
          break;
        case (ValidationMessages.productionUrlWithWww):
          appUrl = 'https://' + ValidationMessages.productionAPUrl + '/api';
          break;
        case (ValidationMessages.productionNormandyDomain):
          appUrl = 'https://' + ValidationMessages.productionAPUrl + '/api';
          break;
        case (ValidationMessages.productionCanopyDomain):
          appUrl = 'https://' + ValidationMessages.productionAPUrl + '/api';
          break;
        case (ValidationMessages.productionPbibinsDomain):
          appUrl = 'https://' + ValidationMessages.productionAPUrl + '/api';
          break;
        default:
          appUrl = ValidationMessages.stagingUrl + '/api';
      }
      
      const xhr = this.xhr = new XMLHttpRequest();
      xhr.open('POST', appUrl+'/ckeditor-image-upload', true);
      xhr.responseType = 'json';
    }
  
    _initListeners(resolve, reject, file) {
      const xhr = this.xhr;
      const loader = this.loader;
      const genericErrorText = `Couldn't upload file: ${file.name}.`;
  
      xhr.addEventListener('error', () => reject(genericErrorText));
      xhr.addEventListener('abort', () => reject());
      xhr.addEventListener('load', () => {
        const response = xhr.response;
        if (!response || response.error) {
          return reject(response && response.error ? response.error.message : genericErrorText);
        }
  
        resolve({
          default: response.url
        });
      });
   
      if (xhr.upload) {
        xhr.upload.addEventListener('progress', evt => {
          if (evt.lengthComputable) {
            loader.uploadTotal = evt.total;
            loader.uploaded = evt.loaded;
          }
        });
      }
    }
  
    _sendRequest(file) {
      const data = new FormData();
      data.append('upload', file);
      this.xhr.send(data);
    }
  
   }
   //End