import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';

@Injectable()
export class PublicquoteService {

  private pubRaterFormsCarrier = new BehaviorSubject({});
  currPubRaterFormsObj = this.pubRaterFormsCarrier.asObservable();

  


  changePubRaterFormsCarrier(formsObj: string)
  {
    this.pubRaterFormsCarrier.next(formsObj);
  }


}