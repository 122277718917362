import { DomSanitizer, SafeResourceUrl  } from '@angular/platform-browser';
import { Component, OnInit } from '@angular/core';
import { DataService } from '../services/data.service';
import { ValidationMessages } from '../validation-messages';

@Component({
  selector: 'app-jot-form',
  templateUrl: './jot-form.component.html',
  styleUrls: ['./jot-form.component.css']
})
export class JotFormComponent implements OnInit {
   
    formUrl:string = '';
    safeUrl: SafeResourceUrl;
    jotFormData:any = '';
    constructor(private sanitizer: DomSanitizer, private dataService: DataService) {
        let environment = window.location.hostname;
        if(environment == ValidationMessages.productionUrl || environment ==  ValidationMessages.productionUrlWithWww || environment == ValidationMessages.productionNormandyDomain || environment == ValidationMessages.productionCanopyDomain || environment == ValidationMessages.productionPbibinsDomain){
            this.formUrl = 'https://form.jotform.com/230893094780161';
        } else{
            this.formUrl = 'https://form.jotform.com/230922896334462';
        }
        console.log(this.formUrl);
        this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.formUrl);
    }

    ngOnInit() {
        this.dataService.changeCurrentHeaderActive('10');
    }

}
