import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators, UntypedFormGroup } from '@angular/forms';
import { UserService } from '../services/user.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { DataService } from '../services/data.service';
import { UserIdleService } from 'angular-user-idle';

@Component({
  selector: 'app-login-modal',
  templateUrl: './login-modal.component.html',
  styleUrls: ['./login-modal.component.css', '../../assets/fcss/bootstrap.css', '../../assets/fcss/default.css', '../../assets/fcss/AdminLTE.css', '../../assets/fcss/_all-skins.css']
})
export class LoginModalComponent implements OnInit {

  loginForm: UntypedFormGroup;
  submitted = false;
  loginError = false;
  loginUserName = localStorage.getItem('loginUserName');
  loginPassword = localStorage.getItem('loginPassword');
  loginRemberMe = localStorage.getItem('loginRememberme');

  activeMenu:any;
  loggedStatus:any;
  constructor(public router: Router, private formBuilder: UntypedFormBuilder, public userService:UserService, private spinner: NgxSpinnerService, public dataService: DataService,private userIdle: UserIdleService) { }

  goToSignUp(){
    this.loginError = false;
    this.submitted = false;
    this.loginForm.reset();
    document.getElementById('closeLoginId').click();
    this.router.navigate(['/signup']);
  }

  openForgotModal(){
    this.loginError = false;
    this.submitted = false;
    this.loginForm.reset();
    document.getElementById('closeLoginId').click();
    document.getElementById('forgotModalId').click();
  }

  resetFormValues() {
    this.loginError = false;
    this.submitted = false;
    this.loginForm.reset();
  }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      u_email: ['', Validators.required],
      u_password: ['', Validators.required]
    });
    
    if(localStorage.getItem('loginRememberme') == '1'){
      $('#loginRememberCheck').prop('checked', true);
      this.loginForm.patchValue({
        u_email: this.loginUserName,
        u_password:this.loginPassword
      });
    }
    
     this.userIdle.startWatching();

     this.userIdle.onTimerStart().subscribe(count=> {
       var eventList= ['click', 'mouseover','keydown','DOMMouseScroll','mousewheel',
       'mousedown','touchstart','touchmove','scroll','keyup'];
     for(let event of eventList) {
       document.body.addEventListener(event, () =>this.userIdle.resetTimer());
     }
     });
     
     this.userIdle.onTimeout().subscribe(() => {
       this.spinner.show();
       localStorage.clear();
       localStorage.setItem('active_tab', '1');
       this.dataService.changeCurrentHeaderActive('1');
       this.dataService.currentHeaderActive.subscribe(status => this.activeMenu = status);
       this.dataService.changeCurrentLoggedStatus('0');
       this.dataService.currentLoggedStatus.subscribe(status => this.loggedStatus = status);
       this.spinner.hide();
       this.router.navigate(['/home']);  
     });

  }

  get f() { return this.loginForm.controls; }

  saveLoginInfo(){
    this.loginError = false;
    this.submitted = true;
    if (this.loginForm.invalid) {
      return;
    }

    this.submitted = false;
    var postData = {};
    postData = this.loginForm.value;
    postData['type'] = 2;
    this.spinner.show();
    this.userService.userOperations(postData).subscribe(data => {
      this.checkUserData(data);
    });
  }

  checkUserData(userInfo:any){
    if(userInfo.output == 0){
      this.loginError = true;
    }else{
      localStorage.setItem('loggedUserToken', userInfo.token);
      localStorage.setItem('PMAXLoggedIn','0');
      localStorage.setItem('insuredAutoSave','0');
      localStorage.setItem('insuredTabForm',JSON.stringify({}));
      localStorage.setItem('userLoggedInLoad', '1');
      localStorage.setItem('u_id', userInfo.userData.u_id);

      localStorage.setItem('u_agent_broker', userInfo.userData.u_agent_broker);
      localStorage.setItem('u_user_broker', userInfo.userData.u_user_broker);

     
      if(userInfo.wholeselar == 1){
        localStorage.setItem('u_ur_id', '3');
        localStorage.setItem('PMAXLoggedIn','1');
      }else if(userInfo.userData.u_agent_broker == 1){
        localStorage.setItem('u_ur_id', '3');
        localStorage.setItem('PMAXLoggedIn','1');
      }else if(userInfo.userData.u_ur_id == 5){
        localStorage.setItem('u_ur_id', '3');
        localStorage.setItem('PMAXLoggedIn','1');
      }else if(userInfo.userData.u_ur_id == 6){
        localStorage.setItem('u_ur_id', '3');
      }else{
        localStorage.setItem('u_ur_id', userInfo.userData.u_ur_id);
      }
      localStorage.setItem('u_email', userInfo.userData.u_email);
      localStorage.setItem('u_name', userInfo.userData.u_name);
      localStorage.setItem('u_login_id', userInfo.userData.u_login_id);
      localStorage.setItem('u_first_name', userInfo.userData.u_first_name);
      localStorage.setItem('u_last_name', userInfo.userData.u_last_name);
      localStorage.setItem('qu_id', userInfo.userData.qu_id);
      localStorage.setItem('hasRenewal', userInfo.hasRenewal);
      localStorage.setItem('renewalQuotesList','0');
      localStorage.setItem('expiredQuotesList','0');
      this.dataService.changeCurrentHeaderDisplayName(userInfo.userData.u_email);
      this.dataService.changeCurrentLoggedStatus('1');
      this.dataService.currentLoggedStatus.subscribe(status => this.loggedStatus = status);
      
      if(typeof localStorage.getItem('previous_page') != 'undefined' && localStorage.getItem('previous_page') != null && localStorage.getItem('previous_page') != 'null' && localStorage.getItem('previous_page') != ""){
        let previousPageUrl = localStorage.getItem('previous_page');
        localStorage.setItem('active_tab', '2');
        this.dataService.changeCurrentHeaderActive('2');
        this.dataService.currentHeaderActive.subscribe(status => this.activeMenu = status);
        localStorage.removeItem('previous_page');
        this.router.navigate(['/'+previousPageUrl]);
      
      }
      else{
        if(userInfo.userData.u_ur_id != 4 && userInfo.userData.u_ur_id != 3 && userInfo.userData.u_ur_id != 5 && userInfo.userData.u_ur_id != 6){
          localStorage.setItem('active_tab', '2');
          this.dataService.changeCurrentHeaderActive('2');
          this.dataService.currentHeaderActive.subscribe(status => this.activeMenu = status);
          this.router.navigate(['/quotes']);
        }else if(userInfo.userData.u_ur_id == 3 || userInfo.userData.u_ur_id == 5 || userInfo.userData.u_ur_id == 6){
          localStorage.setItem('active_tab', '2');
          this.dataService.changeCurrentHeaderActive('2');
          this.dataService.currentHeaderActive.subscribe(status => this.activeMenu = status);
          localStorage.setItem('brokerViewId', userInfo.userData.u_id);
         
          if(localStorage.getItem('wholesalebroker') == '1' || localStorage.getItem('checkDisplayQuotes') == '1'){
            this.router.navigate(['/wbroker-quotes']);
          }else{
            this.router.navigate(['/broker-quotes']);
          }
        }else{
          localStorage.setItem('active_tab', '0');
          this.dataService.changeCurrentHeaderActive('0');
          this.dataService.currentHeaderActive.subscribe(status => this.activeMenu = status);
          localStorage.setItem('new_quote_id', '2');
          localStorage.setItem('qu_id', userInfo.userData.qu_id);
          this.router.navigate(['/quote-summary', userInfo.userData.qu_id]);
        }
      }
    }
    this.spinner.hide();
  }
  loginRemember(e){  
    if(e.target.checked){
      localStorage.setItem('loginUserName',this.loginForm.value.u_email);
      localStorage.setItem('loginPassword',this.loginForm.value.u_password);
      localStorage.setItem('loginRememberme','1');
    }else{
      localStorage.setItem('loginUserName','');
      localStorage.setItem('loginPassword','');
      localStorage.setItem('loginRememberme','0');
    }
  }
}
