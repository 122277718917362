<div class="modal fade" id="login" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" data-backdrop="static" data-keyboard="false">
        <div class="modal-dialog login_modal_w" role="document">
          <div class="modal-content">
            <div class="modal-header login_header_bg">
              <button type="button" class="close" data-dismiss="modal" aria-label="Close" id="closeLoginId" (click)="resetFormValues()"><span aria-hidden="true">&times;</span></button>
              <h4 class="modal-title text-center" id="myModalLabel">Login</h4>
            </div>
            <div class="modal-body">
                <form [formGroup]="loginForm">
                    <div class="form-group">
                        <label for="exampleInputEmail1">Email ID / Producer ID</label>
                        <input type="email" class="form-control" formControlName="u_email" (keyup.enter)="saveLoginInfo()">
                        <div *ngIf="submitted && f.u_email.errors" class="invalid-feedback">
                            <div *ngIf="f.u_email.errors.required" style="color:red;font-size: 15px;">Email is required</div>
                          
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="exampleInputPassword1">Password</label>
                        <input type="password" class="form-control" formControlName="u_password" (keyup.enter)="saveLoginInfo()">
                        <div *ngIf="submitted && f.u_password.errors" class="invalid-feedback">
                            <div *ngIf="f.u_password.errors.required" style="color:red;font-size: 15px;">Password is required</div>
                        </div>
                    </div>
                    <div class="form-group" style="margin-top:20px">
                        <div  class="checkbox"><label  class="checkboxcontainer"><input  type="checkbox" id="loginRememberCheck" (click)="loginRemember($event)"> Remember me <span  class="checkboxmark"></span></label></div>
                    </div>
                    <p text-center style="color:red" [hidden]="!loginError">Invalid login credentials.</p>
                    <div class="form-group login_btn">
                        <button type="button" class="btn btn-primary btn-block" (click)="saveLoginInfo()">Login</button>
                    </div>
                    <div class="row">
                        <div class="col-md-7 text-left">
                            <small>Don’t have an account? <a href="javascript:void(0)" (click)="goToSignUp()">Sign Up</a></small>
                        </div>
                        <div class="col-md-5 text-right">
                            <small><a href="javascript:void(0)" (click)="openForgotModal()">Forgot Password?</a></small>
                        </div>
                    </div>
                </form>
            </div>
        </div>
      </div>
    </div>