import {
    HttpEvent,
    HttpHandler,
    HttpRequest,
    HttpErrorResponse,
    HttpInterceptor
} from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { Inject, Injectable } from '@angular/core';
import { ApiService } from './api.service';


@Injectable()
export class ErrorIntercept implements HttpInterceptor {
    constructor(@Inject(NgxSpinnerService) private spinner: NgxSpinnerService, private apiservice: ApiService) { }

    errorMessage:any = '';
    displayMessage:any = '';

    intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
       return next.handle(request)
            .pipe(
                retry(1),
                catchError((error: HttpErrorResponse) => {

                    if (error.error instanceof ErrorEvent) {
                        this.errorMessage = `Error: ${error.error.message}`;
                        this.displayMessage = 'Sorry, Something went wrong. Please try again.';
                    } else {
                        this.errorMessage = `Error Status: ${error.status}\nMessage: ${error.message}`;
                        this.displayMessage = 'Sorry, Something went wrong. Please try again.';
                    }

                    localStorage.setItem('loggedUserToken', localStorage.getItem('loggedUserToken'));

                    this.spinner.hide() 
                    let errorObj ={
                        error:this.errorMessage
                    }
					
                    if(this.errorMessage.includes('0 Unknown Error') || this.errorMessage.includes('Unknown Error')){
                    }else{
                        // window.alert(this.displayMessage);
                        this.apiservice.checkClientUniquee(errorObj).subscribe(data => {});
                    }
					
                    return throwError(this.errorMessage);
                })
            )
    }


}