<div class="hold-transition skin-blue layout-top-nav">
    <div class="wrapper"> 
        <div class="content-wrapper">
            <div class="container-fluid"> 
                <section class="content-header">
                <h1>Payments List</h1>
                </section>
                <section class="content">
                        <div class="box">
                            <div class="box-header with-border pos_r " style="padding: 20px;">    
                                <div class="box-tools fquotelist"> 
                                  <button type="button" (click) = "paidPaymentsList(0)" *ngIf="paymentsstatus == 1" class="btn btn-sm btn-success box-toolsmarginbtn fquotelistbtn btnpaidlist" >Paid List</button>   
                                  <button type="button" (click) = "paidPaymentsList(1)"  *ngIf="paymentsstatus == 0" class="btn btn-sm btn-danger box-toolsmarginbtn fquotelistbtn btnpaidlist" >Unpaid List</button>   
                                  <button type="button" (click) = "openNotificationPopUp()"  *ngIf="paymentsstatus == 1" class="btn btn-sm btn-success box-toolsmarginbtn fquotelistbtn">Send Mail</button>
                                  
                                </div>  
                            </div>
                            <div class="box-body pos_r box-selectmargin">
                                <div class="quotes_tbl" style="overflow-x: scroll !important;">
                                    <table id="adminQuotesList" class="table table-bordered table-striped">
                                        <thead id="table-tfoot">
                                            <tr class="table-header">
                                                <th><div><span class="checkbox" style="float:right"><label class="checkboxcontainer"><input
                                                    type="checkbox" id="allBrokersCheckBox"  [disabled]="paymentscheckbox"><span
                                                    class="checkboxmark"></span></label></span></div></th>
                                                <th>Producer ID</th>
                                                <th>Quote</th>
                                                <th>Effective Date</th>
                                                <th>Policy Number</th> 
                                                <th>Insured</th>
                                                <th>Payment Type</th>
                                                <th>Status</th>
                                                <th>Premium</th>
                                                <th>Net Due</th>
                                                <th>Deposit</th>
                                                <th>Deposit Due Date</th>
                                                <th>Deposit Paid Date</th>
                                                <th>Balance</th>
                                                <th>Balance Due Date</th>
                                            </tr>
                                        </thead>
                               
                                        <tbody></tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                </section>
            </div>
        </div>
    </div>
</div>

<jw-modal id="notification-email-popup">
    <div class="modal-content modal_lg">
      <div class="modal-header">
        <h4 class="modal-title">Send Notification Email</h4>
      </div>
      <div class="modal-body">
        <div>
          <div class="box box_b">
            <div class="box-header with-border">
              <h3 class="box-title"><i class="fa fa-envelope" aria-hidden="true"></i> Send Email</h3>
            </div>
            <div class="box-body">
              <form [formGroup]="sendMailForm" role="form" class="form-horizontal" enctype="multipart/form-data">
                <div class="form-group">
                  <div class="col-lg-12">
                    <input type="text" placeholder="To" class="form-control" formControlName="mail_to">
                    <input type="hidden" formControlName="mail_to_u_id">
                    <div *ngIf="submitted && f.mail_to.errors" class="invalid-feedback">
                      <div *ngIf="f.mail_to.errors.required" style="color:red;font-size: 15px;">To email address is
                        required</div>
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <div class="col-lg-12">
                    <input type="text" placeholder="CC" (blur)="onSelectCCEmails()" class="form-control" id="emailCC" formControlName="mail_cc">
                      <div *ngIf="submitted && !isValidccEmail" style="color:red;font-size: 15px;">
                        Please enter valid Email Ids
                      </div>
                  </div>
                </div>
    
                            <div class="form-group">
                              <div class="col-lg-12">
                                  <select class="form-control" formControlName="mail_tmp"
                                      (change)="displayTemplate1()">
                                      <option value="">-- Select Template --</option>
                                      <option *ngFor="let tm of notificationTemplates; let i = index;" [value]="i">{{
                                          tm?.et_sub }}</option>
                                  </select>
                                  <div *ngIf="submitted && f.mail_tmp.errors" class="invalid-feedback">
                                      <div *ngIf="f.mail_tmp.errors.required" style="color:red;font-size: 15px;">
                                          Template is required</div>
                                  </div>
                              </div>
                          </div>

              
                <div class="form-group">
                  <div class="col-lg-12">
                    <input type="text" placeholder="Subject" class="form-control" formControlName="mail_sub">
                    <div *ngIf="submitted && f.mail_sub.errors" class="invalid-feedback">
                      <div *ngIf="f.mail_sub.errors.required" style="color:red;font-size: 15px;">Subject is required</div>
                    </div>
                  </div>
                </div>
  
                <div class="form-group">
                  <div class="col-lg-12">
                    <ckeditor [config]="editorConfig" [editor]="Editor" formControlName="mail_msg" spellcheck="false"
                      (ready)="onReady($event)"></ckeditor>
                    <div *ngIf="submitted && f.mail_msg.errors" class="invalid-feedback">
                      <div *ngIf="f.mail_msg.errors.required" style="color:red;font-size: 15px;">Message is required</div>
                    </div>
                    <div *ngIf="submitted &&  messageBodyValidation" class="invalid-feedback">
                      <div style="color:red;font-size: 15px;">Message Must be a minimum of 20 characters is required</div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button (click)="closeEmailPopUp();" class="btn btn-primary">Close</button>&nbsp;&nbsp;
        <button (click)="SendEmails();" class="btn btn-primary">Send</button>&nbsp;&nbsp;
      </div>
    </div>
  </jw-modal>

  <jw-modal id="assign-admins-message">
    <div class="modal-content modal_sm">
      <div class="modal-header">
        <h4 class="modal-title">Message</h4>
      </div>
      <div class="modal-body">
        <p>Please select brokers.</p>
      </div>
      <div class="modal-footer">
        <button (click)="closeMessagePop();" class="btn btn-primary">Ok</button>
      </div>
    </div>
  </jw-modal>
  