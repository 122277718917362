import { NgxSpinnerService } from 'ngx-spinner';
import { ModalService } from './../_modal/modal.service';
import { QuoteUnderwritingService } from 'src/app/services/quote-underwriting.service';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Component, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-import-policies',
  templateUrl: './import-policies.component.html',
  styleUrls: [
    './import-policies.component.css', 
    '../../assets/css/bootstrap.css', 
    '../../assets/css/default.css', 
    '../../assets/css/AdminLTE.css', 
    '../../assets/css/_all-skins.css', 
    '../../assets/datatables.net/dataTables.bootstrap.css'
  ]
})

export class ImportPoliciesComponent implements OnInit {

  uploadCsvFile: UntypedFormGroup;
  showMsg: string = "";
  outputType: number = null;
  isClickedSubmit: boolean = false;
  noExcessQuote:any = [];

  @ViewChild('policyFile') policyFile: any = '';

  constructor(private formBuilder: UntypedFormBuilder, 
              private quoteService: QuoteUnderwritingService, 
              private modalService: ModalService,
              private spinnerService: NgxSpinnerService
            ) { }

  ngOnInit() {
    this.uploadCsvFile = this.formBuilder.group({
      quoteType: ['', Validators.required],
      selectedFile: ['', Validators.required]
    });
  }

  getFile(event: any){
    this.showMsg = '';
    this.noExcessQuote = [];
    this.uploadCsvFile.controls['selectedFile'].setValue(event.target.files[0]);
  }
  
  submitFile(){
    if(this.uploadCsvFile.invalid){
      this.isClickedSubmit = true;
      return
    }
    var fileData = new FormData();
    fileData.append('quoteType', this.uploadCsvFile.value.quoteType);
    fileData.append('file', this.uploadCsvFile.value.selectedFile);
    this.isClickedSubmit = false;
    this.spinnerService.show();
    this.quoteService.sendData(fileData).subscribe(
      data => {
        this.showMsg = data.message;
        this.outputType = data.output;
        if(data.output == 5){
          this.noExcessQuote = data.noExcessQuotes;
          if(this.noExcessQuote.length == 0){
            this.showMsg = "Quotes have been imported successfully.";
          }
        }
        this.spinnerService.hide();
      }
    );
  }
  
  onSelectQuoteType(){
    this.showMsg = ''
    this.noExcessQuote = [];
  }
}
