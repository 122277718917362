<ngx-spinner bdColor="rgba(255, 255, 255, 0.2)"size="medium"color="#000" type="ball-scale-multiple"> 
        <div class="" [ngClass]="loaderImageClass"><img src='{{loaderImagePath}}' ></div>
</ngx-spinner>
<div style="min-height:600px" class="app-min-height">
<div class="home_pad"><app-headerfront [domainData]="wholesalerInfoArray" *ngIf="wholesalerInfoArray != '' && ((loggedStatus == '0' && activeMenu != '100') || activeMenu == '1' || activeMenu == '6' || activeMenu == '7')"></app-headerfront></div>
<app-header *ngIf="loggedStatus != '0' && activeMenu != '100' && activeMenu != '1' && activeMenu != '6' && activeMenu != '7'"></app-header>

<app-breadcrum *ngIf="loggedStatus != '0' && activeMenu != '100' && activeMenu != '1' && activeMenu != '6' && activeMenu != '7' && activeMenu != '10'"></app-breadcrum>

<router-outlet></router-outlet>
</div>
<app-footerfront [domainData]="wholesalerInfoArray" *ngIf="wholesalerInfoArray != '' && ((loggedStatus == '0' && activeMenu != '100') || activeMenu == '1' || activeMenu == '6' || activeMenu == '7')"></app-footerfront>
<app-footer *ngIf="loggedStatus != '0' && activeMenu != '100' && activeMenu != '1' && activeMenu != '6' && activeMenu != '7'"></app-footer>

<jw-modal id="user-delete-confirmation">
  <div class="modal-content modal_sm">
      <div class="modal-header">
          <h4 class="modal-title">Confirmation</h4>
      </div>
      <div class="modal-body">
          <p>User is Deleted..!</p>
      </div>
      <div class="modal-footer">
          <button (click)="deleteConfirmation();" class="btn btn-primary">Ok</button>&nbsp;&nbsp;
      </div>
  </div>
</jw-modal>

<jw-modal id="user-hidden-confirmation">
    <div class="modal-content modal_sm">
        <div class="modal-header">
            <h4 class="modal-title">Confirmation</h4>
        </div>
        <div class="modal-body">
            <p>User is Not Active..!</p>
        </div>
        <div class="modal-footer">
            <button (click)="deleteConfirmation();" class="btn btn-primary">Ok</button>&nbsp;&nbsp;
        </div>
    </div>
  </jw-modal>

  <jw-modal id="user-check-network-information">
    <div class="modal-content modal_sm">
        <div class="modal-header">
            <h4 class="modal-title">Confirmation</h4>
        </div>
        <div class="modal-body">
            <p>User is OFFLINE. Please Check Network Connection..!</p>
        </div>
        <div class="modal-footer" >
            <button (click)="networkInformation();" class="btn btn-primary">Ok</button>&nbsp;&nbsp;
        </div>
    </div>
  </jw-modal>