<div class="hold-transition  layout-top-nav" [style.background-color]="backgroundColor"
*ngIf="domainname == ''">
    <div class="wrapper">
        <header class="main-header">
            <nav class="navbar navbar-static-top">
                <div class="container">
                    <div class="navbar-header">
                        <a style="width: 350px" href="javascript:void(0);" class="navbar-brand manual-navbar-brand">
                            <img *ngIf=" logo_path != '' " class="header_logo" src="{{logo_path}}">
                        </a>
                  
                    </div>
                
                    <div class="navbar-custom-menu" *ngIf=" backgroundColor == ''">
                        <ul class="nav navbar-nav">
                       
                            <li class="dropdown notifications-menu mm_hide">
                                <a href="javascript:void(0);" class="dropdown-toggle">
                                    <img src="assets/images/phone_n.png?v=3214" />
                                </a>
                            </li>
                            <li>
                               
                                <a style="display:none" href="#" class="btn btn-warning loginbtn" data-toggle="modal"
                                    data-target="#forgotPass" id="forgotModalId">&nbsp;</a>
                            </li>

                        </ul>
                    </div>
                </div>
            </nav>
        </header>
    </div>
</div>
<link *ngIf="nameofdomain == 'normandy'" rel="stylesheet" href="https://fonts.googleapis.com/css?family=Material+Icons|Open+Sans:400,600,700">
<div class="nav-style" *ngIf="nameofdomain == 'normandy'">
    <nav class="nav_w"> 
        <a href="tel:+18666886442" id="number">
            <div class="phone"><i class="material-icons call-icon">call</i> &nbsp;866-688-6442</div>
        </a>
        <div class="main-nav">
            <a target="_blank" href="https://www.normandyins.com/index.html">
                <img src="{{logo_path}}" alt="normandy insurance company logo"></a>
        </div>
        <div id="cssmenu" class="align-center">
            <div id="menu-line" style="width: 154px; left: 0px;"></div>
            <div id="menu-button"> </div>
            <ul class="space">
                <li class="has-sub"><span class="submenu-button"></span><a class="pointer">Insurance Agents</a>
                    <ul>
                        <li><a target="_blank" href="https://nhicph.jw-filehandler.com/wfNHICPortalLogin.aspx">Agency Log In</a></li>
                        <li><a target="_blank" href="https://www.normandyins.com/become-agent.html">Become an Agent</a></li>
                        <li><a target="_blank" href="https://www.normandyins.com/underwriting-guidelines.html">Underwriting
                                Guidelines</a></li>
                        <li><a target="_blank" href="https://www.normandyins.com/rater-app.html">Online Rator</a></li>
                    </ul>
                </li>
                <li class="has-sub"><span class="submenu-button"></span><a class="pointer">Employers</a>
                    <ul>
                        <li><a target="_blank" href="https://nhicph.jw-filehandler.com/wfNHICPortalLogin.aspx">Pay My Bill</a></li>
                        <li><a target="_blank" href="https://www.normandyins.com/get-quote-2.html">Get a Quote</a></li>
                        <li><a target="_blank" href="https://www.normandyins.com/report-claim.html">Report a Claim</a></li>
                        <li><a target="_blank" href="https://www.normandyins.com/find-doctor.html">Find a Doctor</a></li>
                        <li><a target="_blank" href="https://www.normandyins.com/loss-prevention.html">Loss Prevention</a></li>
                        <li><a target="_blank" href="https://www.normandyins.com/forms-and-resources.html">Forms &amp; Resources</a>
                        </li>
                    </ul>
                </li>
                <li class="has-sub"><span class="submenu-button"></span><a class="pointer">Employees</a>
                    <ul>
                        <li><a target="_blank" href="https://www.normandyins.com/report-claim.html">Report a Claim</a></li>
                        <li><a target="_blank" href="https://www.normandyins.com/find-doctor.html">Find a Doctor</a></li>
                    </ul>
                </li>
                <li><a target="_blank" href="https://nhicph.jw-filehandler.com/wfNHICPortalLogin.aspx">Pay My Bill</a></li>
                <li><a target="_blank" href="https://www.normandyins.com/find-doctor.html">Find a Doctor</a></li>
                <li><a target="_blank" href="https://www.normandyins.com/contact-us.html">Contact</a></li>
            </ul>
        </div>
    </nav>
</div>

<div class="hold-transition  layout-top-nav bshadow" [style.background-color]="backgroundColor"
*ngIf="nameofdomain == 'canopy'">
    <div class="wrapper">
        <header class="main-header">
            <nav class="navbar navbar-static-top">
                <div class="container">
                    <div class="navbar-header">
                        <a style="width: 350px" href="javascript:void(0);" class="navbar-brand">
                            <img *ngIf=" logo_path != '' " class="header_logo" src="{{logo_path}}">
                        </a>
                        <button type="button" class="navbar-toggle collapsed" data-toggle="collapse"
                            data-target="#navbar-collapse">
                            <i class="fa fa-bars"></i>
                        </button>
                    </div>
                  
                    <div class="collapse navbar-collapse pull-left" id="navbar-collapse">
                        <ul class="nav navbar-nav">
                            <li class="{{ activeMenu == 1 ? 'active' : '' }}"><a href="javascript:void(0);" [style.color]="textColor"
                                  href="">Home <span class="sr-only">(current)</span></a></li>
                            <li><a [style.color]="textColor"  target="_blank" href="https://www.canopyspecialty.com/about-us">About us</a></li>
                            <li><a [style.color]="textColor"  target="_blank" href="https://www.canopyspecialty.com/services"
                                target="_blank">Services</a></li>
                            <li><a [style.color]="textColor" target="_blank" href="https://www.canopyspecialty.com/opportunities">Opportunities</a></li>
    
                        </ul>
                    </div>
                  
                    <div class="navbar-custom-menu" *ngIf="this.backgroundColor == ''">
                        <ul class="nav navbar-nav">
                         
                            <li class="dropdown notifications-menu mm_hide">
                                <a href="javascript:void(0);" class="dropdown-toggle">
                                    <img src="assets/images/phone_n.png?v=3214" />
                                </a>
                            </li>
                            <li>
                               
                                <a style="display:none" href="#" class="btn btn-warning loginbtn" data-toggle="modal"
                                    data-target="#forgotPass" id="forgotModalId">&nbsp;</a>
                            </li>
                           
                        </ul>
                    </div>
                </div>
            </nav>
        </header>
    </div>
</div>

<link *ngIf="nameofdomain == 'pbibins'" rel="stylesheet" href="https://fonts.googleapis.com/css?family=Material+Icons|Open+Sans:400,600,700">
<div class="nav-style" *ngIf="nameofdomain == 'pbibins'">
    <nav class="nav_w"> 
        <a href="tel:+18778937629" id="number">
            <div class="phone"><i class="material-icons call-icon">call</i> &nbsp;877-893-7629</div>
        </a>
        <a href="tel:+18666886442" id="number" style="    margin-left: 129px; padding: 0px;">
            <div class="phone"><i class="material-icons mail-icon" style="font-size: 20px;">mail</i> &nbsp;info@pbibinc.com</div>
        </a>
        <div class="main-nav">
            <a target="_blank" href="https://pbibins.com/">
                <img src="{{logo_path}}" alt="Pascal Burke Insurance POWERED BY Jaspar Technologies LLC logo"></a>
        </div>
        <div id="cssmenu" class="align-center">
            <div id="menu-line" style="width: 154px; left: 0px;"></div>
            <div id="menu-button"> </div>
            <ul class="space">
                <li class="has-sub"><span class="submenu-button"></span><a class="pointer">Contractors’ Insurance</a>
                    <ul style="background-color: #efefef;">
                        <li><a target="_blank" href="https://pbibins.com/contractor-general-liability/" style="background-color: #efefef; color: black;">Contractor General Liability</a></li>
                        <li><a target="_blank" href="https://pbibins.com/contractor-bonds/" style="background-color: #efefef; color: black;">License Bonds</a></li>
                        <li><a target="_blank" href="https://pbibins.com/commercial-auto/" style="background-color: #efefef; color: black;">Commercial Auto</a></li>
                        <li><a target="_blank" href="https://pbibins.com/roofer/" style="background-color: #efefef; color: black;">Roofer</a></li>
                        <li><a target="_blank" href="https://pbibins.com/fire-protection/" style="background-color: #efefef; color: black;">Fire Protection</a></li>
                        <li><a target="_blank" href="https://pbibins.com/commercial-property-coverage/" style="background-color: #efefef; color: black;">Commercial Property Coverage</a></li>
                        <li><a target="_blank" href="https://pbibins.com/workers-compensation/" style="background-color: #efefef; color: black;">Workers’ Compensation</a></li>
                        
                        <li class="has-sub"><span class="submenu-button"></span><a class="pointer"target="_blank" href="https://pbibins.com/builders-risk/" style="background-color: #efefef; color: black;">Builder’s Risk</a>
                            <ul>
                             <li><a target="_blank" href="https://pbibins.com/installation-floater-2/" style="background-color: #efefef; color: black;">Installation Floater</a></li>
                             <li><a target="_blank" href="https://pbibins.com/tools-equipment-insurance/" style="background-color: #efefef; color: black;">Tools & Equipment Insurance</a></li>
                            </ul>
                        </li>
                        <li><a target="_blank" href="https://pbibins.com/umbrella-excess-insurance/" style="background-color: #efefef; color: black;">Umbrella/Excess Insurance</a></li>
                        <li><a target="_blank" href="https://btglrater.pbibins.com/" style="background-color: #efefef; color: black;">Small Contractor GL</a></li>
                    </ul>
                </li>
                <li class="has-sub"><span class="submenu-button"></span><a class="pointer" > Small Businesses</a>
                    <ul>
                        <li class="has-sub"><span class="submenu-button"></span><a class="pointer" style="background-color: #efefef; color: black;">Small Businesses</a>
                            <ul>
                                <li><a target="_blank" href="https://pbibins.com/small-business-gl/" style="background-color: #efefef; color: black;">General Liability</a></li>
                                <li><a target="_blank" href="https://pbibins.com/small-business-surety-bonds/" style="background-color: #efefef; color: black;">Surety Bonds</a></li>
                                <li><a target="_blank" href="https://pbibins.com/small-business-commercial-auto/" style="background-color: #efefef; color: black;">Commercial Auto</a></li>
                                <li><a target="_blank" href="https://pbibins.com/small-business-commercial-property/" style="background-color: #efefef; color: black;">Commercial Property Coverage</a></li>
                                <li><a target="_blank" href="https://pbibins.com/small-business-workers-comp/" style="background-color: #efefef; color: black;">Worker’s Compensation</a></li>
                                <li><a target="_blank" href="https://pbibins.com/sb-umbrella-excess-insurance/" style="background-color: #efefef; color: black;">Umbrella/Excess Insurance</a></li>
                            </ul>
                        </li>
                        <li class="has-sub"><span class="submenu-button"></span><a class="pointer" style="background-color: #efefef; color: black;">Trucking</a>
                            <ul>
                                <li><a target="_blank" href="https://pbibins.com/primary-auto-liability/" style="background-color: #efefef; color: black;">Primary Auto Liability</a></li>
                                <li><a target="_blank" href="https://pbibins.com/comprehensive-physical-damage-and-collision-waiver/" style="background-color: #efefef; color: black;">Comprehensive Physical Damage and Collision Waiver</a></li>
                                <li><a target="_blank" href="https://pbibins.com/motor-truck-cargo-coverage/" style="background-color: #efefef; color: black;">Motor Truck Cargo Coverage</a></li>
                            </ul>
                        </li>
                        
                    </ul>
                </li>
                <li class="has-sub"><span class="submenu-button"></span><a class="pointer">Policy Holders</a>
                    <ul>
                        <li class="has-sub"><span class="submenu-button"></span><a class="pointer" style="background-color: #efefef; color: black;">Manage Your Policy</a>
                            <ul>
                                <li><a target="_blank" href="https://pbibins.com/certificate-request/" style="background-color: #efefef; color: black;">Certificate Request</a></li>
                                <li><a target="_blank" href="https://pbibins.com/policy-documents-request/" style="background-color: #efefef; color: black;">Policy Documents Request</a></li>
                                <li><a target="_blank" href="https://pbibins.com/request-loss-runs/" style="background-color: #efefef; color: black;">Request Loss Runs</a></li>
                            </ul>
                        </li>
                        <li class="has-sub"><span class="submenu-button"></span><a class="pointer" style="background-color: #efefef;color: black;">Claims</a>
                            <ul>
                                <li><a target="_blank" href="https://pbibins.com/claims-center/" style="background-color: #efefef; color: black;">Claims Center</a></li>
                            </ul>
                        </li>
                        <li class="has-sub"><span class="submenu-button"></span><a class="pointer"style="background-color: #efefef; color: black;">Customer Service</a>
                            <ul>
                                <li><a target="_blank" href="https://pbibins.com/about-us/" style="background-color: #efefef; color: black;">About Us</a></li>
                                <li><a target="_blank" href="https://pbibins.com/get-a-quote/" style="background-color: #efefef; color: black;">Get a Quote</a></li>
                                <li><a target="_blank" href="https://pbibins.com/frequently-asked-questions/" style="background-color: #efefef; color: black;">FAQs</a></li>
                                <li><a target="_blank" href="https://pbibins.com/frequently-asked-questions-and-insurance-terms/" style="background-color: #efefef; color: black;">Insurance Terms</a></li>
                                <li><a target="_blank" href="https://pbibins.com/contact-us/" style="background-color: #efefef; color: black;">Contact</a></li>
                            </ul>
                        </li>
                    </ul>
                </li>
            </ul>
        </div>
    </nav>
</div>
<div class="nav-style" [style.background-color]="backgroundColor" *ngIf="nameofdomain == 'unetins'">
    <div class="wrapper">
        <header class="main-header">
            <nav class="navbar navbar-static-top">
                <div class="container">
                    <div class="navbar-header">
                        <a style="width: 350px" href="javascript:void(0);" class="navbar-brand">
                            <img *ngIf=" logo_path != '' " class="header_logo" src="{{logo_path}}">
                        </a>
                        <button type="button" class="navbar-toggle collapsed" data-toggle="collapse"
                            data-target="#navbar-collapse">
                            <i class="fa fa-bars"></i>
                        </button>
                    </div>
             
                    <div class="navbar-custom-menu" >
                        <ul class="nav navbar-nav">
                          
                            <li class="dropdown notifications-menu mm_hide">
                                <a href="javascript:void(0);" class="dropdown-toggle">
                                    <img src="assets/images/phone_n.png?v=3214" />
                                </a>
                            </li>
                            <li>
                               
                                <a style="display:none" href="#" class="btn btn-warning loginbtn" data-toggle="modal"
                                    data-target="#forgotPass" id="forgotModalId">&nbsp;</a>
                            </li>

                        </ul>
                    </div>
                </div>
            </nav>
        </header>
    </div>
</div>

<app-login-modal></app-login-modal>
<app-forgotpassword></app-forgotpassword>
<jw-modal id="logout-modal">
    <div class="modal-content modal_sm">
        <div class="modal-header">
            <h4 class="modal-title">Message</h4>
        </div>
        <div class="modal-body">
            <p>Are you sure want to logout?</p>
        </div>
        <div class="modal-footer">
            <button (click)="logoutConfirm()" class="btn btn-primary">Yes</button>
            <button (click)="logoutConfirmCancel()" class="btn btn-light">Cancel</button>
        </div>
    </div>
</jw-modal>