import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { ValidationMessages } from './validation-messages';


@Injectable({
  providedIn: 'root'
})
export class ApiService {
  apiURL = "";
  constructor(private httpClient: HttpClient) {     
      var environmentt: string;
      var appUrl: string;
      environmentt = window.location.hostname;
      switch (environmentt) {
        case 'localhost':
          appUrl = 'http://localhost:8000/api';
          break;
        case (ValidationMessages.productionUrl):
          appUrl = 'https://' + ValidationMessages.productionAPUrl + '/api';
          break;
        case (ValidationMessages.productionUrlWithWww):
          appUrl = 'https://' + ValidationMessages.productionAPUrl + '/api';
          break;
        case (ValidationMessages.productionNormandyDomain):
          appUrl = 'https://' + ValidationMessages.productionAPUrl + '/api';
          break;
        case (ValidationMessages.productionCanopyDomain):
          appUrl = 'https://' + ValidationMessages.productionAPUrl + '/api';
          break;
        case (ValidationMessages.productionPbibinsDomain):
          appUrl = 'https://' + ValidationMessages.productionAPUrl + '/api';
          break;
        
        default:
          appUrl = ValidationMessages.stagingUrl + '/api';
      }
      this.apiURL = appUrl;    
      
  }

  randomString(length) {
    var text = "";
    var possible = 'abcdefghijklmnopqrstuvwxyz'
    +'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
    +'0123456789!@#$%^&*()';
    for (var i = 0; i < length; i++)
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    return text;
  }

  getNewToken(){
    let localStToken = localStorage.getItem('loggedUserToken');
    let decryptToken = atob(atob(btoa(atob(localStToken))));
    let currentIntValue =  parseInt(decryptToken.charAt(7)+''+decryptToken.charAt(25)+''+decryptToken.charAt(37));
    let nextIntValue = 100;
    if(currentIntValue < 999){
      nextIntValue = currentIntValue+1;
    }
    let token = this.randomString(7)+''+nextIntValue.toString().charAt(0)+''+this.randomString(17)+''+nextIntValue.toString().charAt(1)+''+this.randomString(11)+''+nextIntValue.toString().charAt(2)+''+this.randomString(6);
    let encryptToken = btoa(btoa(atob(btoa(token))));
    localStorage.setItem('loggedUserToken', encryptToken);
    return encryptToken;
  }

  public getClaimsDataView( postData: any ): Observable<any>
  { 
    postData['accessToken'] = this.getNewToken();
    postData['tokenUniqueId'] = localStorage.getItem('loggedInMbId');

    const url = `${this.apiURL}/claims-list-row`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    let options = { headers: headers };
    return this.httpClient.post(url, JSON.stringify(postData), options);
  }

  public unassignClaimsDataView( postData: any ): Observable<any>
  { 
    postData['accessToken'] = this.getNewToken();
    postData['tokenUniqueId'] = localStorage.getItem('loggedInMbId');

    const url = `${this.apiURL}/unassign-claims`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    let options = { headers: headers };
    return this.httpClient.post(url, JSON.stringify(postData), options);
  } 
  public checkClientUnique( postData: any ): Observable<any>
  {    
    const url = `${this.apiURL}/check-client-unique`;
    return this.httpClient.post(url, postData);
  }
  
  public savePublicNewQuote( postData: any ): Observable<any>
  {    
    const url = `${this.apiURL}/save-public-quote`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    let options = { headers: headers };
    return this.httpClient.post(url, JSON.stringify(postData), options);
  }

  public updatePolicyDetailsInfo( postData: any ): Observable<any>
  {    
    const url = `${this.apiURL}/save-policy-details`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    let options = { headers: headers };
    return this.httpClient.post(url, JSON.stringify(postData), options);
  }
  public updPolicySubCost( postData: any ): Observable<any>
  {    
    const url = `${this.apiURL}/underwriting-update-cost`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    let options = { headers: headers };
    return this.httpClient.post(url, JSON.stringify(postData), options);
  }

  public getPolicyDetails( qu_id:any,callType:number = 0 ): Observable<any>
  {
    const url = `${this.apiURL}/fetch-policy-details/show/${qu_id}-${callType}`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    let options = { headers: headers };
    return this.httpClient.get(url, options);
  }
  public getDateRates( postData: any ): Observable<any>
  {
    const url = `${this.apiURL}/get-date-rates`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    let options = { headers: headers };
    return this.httpClient.post(url, JSON.stringify(postData), options);
  }
  public ueGetExpiry( postData: any ): Observable<any>
  {
    const url = `${this.apiURL}/get-ue-expiry`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    let options = { headers: headers };
    return this.httpClient.post(url, JSON.stringify(postData), options);
  }
  
  public getStateByZipcode(id: any): Observable<any>{
    return this.httpClient.get(`${this.apiURL}/getstatecode/${id}`);
  }
  public getterritoryByZipandState(postData: any): Observable<any>{
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    let options = { headers: headers };
    const url = `${this.apiURL}/getterritorycode`;
    return this.httpClient.post(url,  JSON.stringify(postData), options);
   
  }

  public getDescByClasscode(classcodesdata: any){    
    var timeStamp = Math.floor(Date.now() / 1000);
    const url = `${this.apiURL}/getstatecode`+"?t="+timeStamp;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    let options = { headers: headers };
    return this.httpClient.post(url, JSON.stringify(classcodesdata), options);
  }

  public getRateCalculationsapi(classcodesdata: any){    
    var timeStamp = Math.floor(Date.now() / 1000);
    const url = `${this.apiURL}/getstatecode`+"?t="+timeStamp;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    let options = { headers: headers };
    return this.httpClient.post(url, JSON.stringify(classcodesdata), options);
  }

  public deleteCalculationsapi(quoteid:any){    
    var timeStamp = Math.floor(Date.now() / 1000);
    const url = `${this.apiURL}/activity/delete/${quoteid}`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    let options = { headers: headers };
    return this.httpClient.get(url, options);
  }

  
  getStateClCodes( codesPostObj:any ): Observable<any>
  {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    let options = { headers: headers };
    return this.httpClient.post(this.apiURL + '/class-codes-search', JSON.stringify(codesPostObj), options);
  }
   
  public getAllDescsByState(classcodesdata: any): Observable<any>
  {    
    var timeStamp = Math.floor(Date.now() / 1000);
    const url = `${this.apiURL}/getstatecode`+"?t="+timeStamp;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    let options = { headers: headers };
    return this.httpClient.post(url, JSON.stringify(classcodesdata), options);
  }

  public callMasters( postObj: any ): Observable<any>
  {    
    var timeStamp = Math.floor(Date.now() / 1000);
    const url = `${this.apiURL}/fetch-masters`+"?t="+timeStamp;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    let options = { headers: headers };
    return this.httpClient.post(url, JSON.stringify(postObj), options);
  }

  public saveLHFile(postData: any): Observable<any>
  {
    const url = `${this.apiURL}/save-lh-file`;
    return this.httpClient.post(url, postData);
  }

  public delLHFile(postData: any): Observable<any>
  {
    const url = `${this.apiURL}/delete-lh-file`;
    return this.httpClient.post(url, postData);
  }

  public saveYBFile(postData: any): Observable<any>
  {
    const url = `${this.apiURL}/save-yb-file`;
    return this.httpClient.post(url, postData);
  }

  public delYBFile(postData: any): Observable<any>
  {
    const url = `${this.apiURL}/delete-yb-file`;
    return this.httpClient.post(url, postData);
  }

  public getEndtQuoteInfo( qu_id:any,qbec_qbe_id:any ): Observable<any>
  {
    const url = `${this.apiURL}/fetch-endt-quote-info/${qu_id}-${qbec_qbe_id}`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    let options = { headers: headers };
    return this.httpClient.get(url, options);
  }

  public renRemAdd( postData: any ): Observable<any>
  {    
    const url = `${this.apiURL}/ren-rem-add`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    let options = { headers: headers };
    return this.httpClient.post(url, JSON.stringify(postData), options);
  }


  // save broker file upload
  public saveBrFile(postData: any): Observable<any>
  {
    const url = `${this.apiURL}/save-br-file`;
    return this.httpClient.post(url, postData);
  }
  public delBrFile(postData: any): Observable<any>
  {
    const url = `${this.apiURL}/delete-br-file`;
    return this.httpClient.post(url, postData);
  }

  public wcMultiUpload(postData: any): Observable<any>
  {
    const url = `${this.apiURL}/save-wc-multiple-uploads`;
    return this.httpClient.post(url, postData);
  }
  public coMultiUpload(postData: any): Observable<any>
  {
    const url = `${this.apiURL}/save-co-multiple-uploads`;
    return this.httpClient.post(url, postData);
  }
  public imuMultiUpload(postData: any): Observable<any>
  {
    const url = `${this.apiURL}/save-imu-policy-uploads`;
    return this.httpClient.post(url, postData);
  }
  public delwcMultiFile(postData: any): Observable<any>
  {
    const url = `${this.apiURL}/delete-wc-policy-multi-file`;
    return this.httpClient.post(url, postData);
  }
  public delcoMultiFile(postData: any): Observable<any>
  {
    const url = `${this.apiURL}/delete-co-policy-multi-file`;
    return this.httpClient.post(url, postData);
  }
  public delimuMultiFile(postData: any): Observable<any>
  {
    const url = `${this.apiURL}/delete-imu-policy-multi-file`;
    return this.httpClient.post(url, postData);
  }
  public saveWCPpolicyform(postData: any): Observable<any>
  {    
    const url = `${this.apiURL}/save-wc-policy-form`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    let options = { headers: headers };
    return this.httpClient.post(url, JSON.stringify(postData), options);
  }
  public saveCOpolicyform(postData: any): Observable<any>
  {    
    const url = `${this.apiURL}/save-co-policy-form`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    let options = { headers: headers };
    return this.httpClient.post(url, JSON.stringify(postData), options);
  }
  public saveIMuPpolicyform(postData: any): Observable<any>
  {    
    const url = `${this.apiURL}/save-imu-policy-form`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    let options = { headers: headers };
    return this.httpClient.post(url, JSON.stringify(postData), options);
  }
  public getBrokerPlacementFee( u_id:any ): Observable<any>
  {
    const url = `${this.apiURL}/get-broker-placement-fee/${u_id}`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    let options = { headers: headers };
    return this.httpClient.get(url, options);
  }

  public checkClientUniquee(postData: any): Observable<any>
  {    
    const url = `${this.apiURL}/send-error-msg-to-admin`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    let options = { headers: headers };
    return this.httpClient.post(url, postData, options);
  }
  public generatecertificateofInsure(postData:any): Observable<any>{
    const url = `${this.apiURL}/geneate-certificate-of-insure`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type','application/x-www-form-urlencoded');
    let options = {headers : headers}
    return this.httpClient.post(url, postData, options);

  }
  public generatelrocertificateofInsure(postData:any): Observable<any>{
    const url = `${this.apiURL}/geneate-lro-certificate-of-insure`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type','application/x-www-form-urlencoded');
    let options = {headers : headers}
    return this.httpClient.post(url, postData, options);

  }
  public getScoreValue(type: number, id: any): Observable<any>{
    let url = '';
    if(type == 1){
      url = `${this.apiURL}/lnriskusers/${id}`;
    } else if(type == 2){
      url = `${this.apiURL}/lnriskcompany/${id}`;
    }
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    let options = { headers: headers }
    return this.httpClient.get(url, options);
  }

  public checkcpplvalidation(postData): Observable<any>{
    const url = `${this.apiURL}/cppl-form-validtion`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type','application/x-www-form-urlencoded');
    let options = {headers : headers}
    return this.httpClient.post(url, postData, options);

  }
  public getLroStateClsCodes(): Observable<any>
  {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    let options = { headers: headers };
    return this.httpClient.get(this.apiURL + '/get-lro-classcodes', options);
  }

  public generateAgentResume(postData:any): Observable<any>{
    const url = `${this.apiURL}/geneate-agent-resume`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type','application/x-www-form-urlencoded');
    let options = {headers : headers}
    return this.httpClient.post(url, postData, options);

  }

  CpplQuoteCancellationOrReinstatement(data: any): Observable<any> {
    const url = `${this.apiURL}/cancel_or_reinstate_cppl_quote`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type','application/x-www-form-urlencoded');
    let options = {headers : headers}
    return this.httpClient.post(url, data, options);
  } 
  deleteCpplQuoteEndorsemet(data: any): Observable<any> {
    const url = `${this.apiURL}/delete_endorsement_cppl_quote`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type','application/x-www-form-urlencoded');
    let options = {headers : headers}
    return this.httpClient.post(url, data, options);
  }

  ImuQuoteCancellationOrReinstatement(data: any) : Observable<any> {
    const url = `${this.apiURL}/cancel_or_reinstate_imu_quote`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type','application/x-www-form-urlencoded');
    let options = {headers : headers}
    return this.httpClient.post(url, data, options);
  } 

  public CPPLStatusUpdateToNonRenewal(postData: any): Observable<any>
  {    
    const url = `${this.apiURL}/cppl-non-renewal`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    let options = { headers: headers };
    return this.httpClient.post(url, JSON.stringify(postData), options);
  }
}
