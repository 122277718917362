import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../services/user.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { DataService } from '../services/data.service';
import { ModalService } from '../_modal';
import { ValidationMessages } from '../validation-messages';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MyUploadAdapter } from '../services/ckeditor-image-upload.service';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
@Component({
  selector: 'app-payments-tab',
  templateUrl: './payments-tab.component.html',
  styleUrls: ['./payments-tab.component.css', '../../assets/css/bootstrap.css', '../../assets/css/default.css', '../../assets/css/AdminLTE.css', '../../assets/css/_all-skins.css', '../../assets/datatables.net/dataTables.bootstrap.css']

})
export class PaymentsTabComponent {
  public Editor = ClassicEditor;
  editorConfig = {
    placeholder: 'Type here..',
    toolbar: [
      'heading', '|',
      'bold', 'italic', '|',
      'link', '|',
      'bulletedList', 'numberedList', '|',
      'uploadImage', '|',
      'undo', 'redo'
    ],
    heading: {
      options: [
        { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
        { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
        { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' }
      ]
    }
  };
  messageBodyValidation: boolean = false; 
  quotesTable:any;
  loaderImagePath: string;
  brokerId: any;
  allpaymenylist:any;
  allCheckedValues: any[];
  submitted: boolean = false;
  sendMailForm: any;
  isValidccEmail: boolean = false;
  tableShow: number;
  loggedUserRole = localStorage.getItem('u_ur_id');
  allreportIdarrs: any[];
  paymentsstatus: number =1;
  paymentscheckbox:  boolean = false;
  requestURL :any;
  notificationTemplates: any[] = [ {'et_sub':'1st Notification','et_body':'1st Notification for the Policy is Due'},{'et_sub':'2nd Notification','et_body':'2nd Notification for the Policy is Due'},{'et_sub':'3rd Notification','et_body':'3rd Notification for the Policy is Due'}];
  constructor(public router: Router, public userService: UserService, private spinner: NgxSpinnerService, public dataService: DataService, private modalService: ModalService, private formBuilder:UntypedFormBuilder) {
    let apiHost = "";
    let environmentt = window.location.hostname;
    switch (environmentt) {
      case 'localhost':
        apiHost = 'http://localhost:8000/api';
        break;
      case (ValidationMessages.productionUrl):
        apiHost = 'https://' + ValidationMessages.productionAPUrl + '/api';
        break;
      case (ValidationMessages.productionUrlWithWww):
        apiHost = 'https://' + ValidationMessages.productionAPUrl + '/api';
        break;
      case (ValidationMessages.productionNormandyDomain):
        apiHost = 'https://' + ValidationMessages.productionAPUrl + '/api';
        break;
      case (ValidationMessages.productionCanopyDomain):
        apiHost = 'https://' + ValidationMessages.productionAPUrl + '/api';
        break;
      case (ValidationMessages.productionPbibinsDomain):
        apiHost = 'https://' + ValidationMessages.productionAPUrl + '/api';
        break;

      default:
        apiHost = ValidationMessages.stagingUrl + '/api';
    }
    this.requestURL =apiHost;
  }
  ngOnInit() {
   
  
    this.sendMailForm = this.formBuilder.group({
      mail_to_u_id: '',
      mail_to: ['', [Validators.required]],
      mail_cc: '',
      mail_sub: ['', Validators.required],
      mail_msg: ['', Validators.required],
      mail_tmp: ['', Validators.required]
    });
    this.displaydatatable();

  }
  paidPaymentsList(status){
    this.spinner.show();
    if(status == 1){
      this.paymentsstatus=1;
      this.paymentscheckbox =false;
    }else{
      this.paymentsstatus=0;
      this.paymentscheckbox =true;
    }
    let serviceUrl =  this.requestURL + '/get-paymentslist?paymentstatus='+this.paymentsstatus;

    
       this.quotesTable.ajax.url(serviceUrl).load();
       this.spinner.hide(); 
  }
  displaydatatable(){ 

    let serviceUrl = this.requestURL + '/get-paymentslist?paymentstatus='+this.paymentsstatus;
    let self = this;
    $(document).ready(function () {
      self.quotesTable = $('#adminQuotesList').DataTable({
        "serverSide": true,
        "searching": true,
        "language": {
          "processing": self.loaderImagePath,
        }, 
        "ordering": true,
        "lengthMenu": [[50, 100, 150, 200], [50, 100, 150, 200]],
        "ajax": {
          url: serviceUrl,
          dataFilter: function (dataa) {
           
            var data = jQuery.parseJSON(dataa);
            self.allpaymenylist = data;
            data.data = data.list;
            return JSON.stringify(data);
          },
        },
        "columns": [
          {
            data: null,
            render: function (data) {
        
                return `<div><span class="checkbox" style="float:right"><label class="checkboxcontainer"><input type="checkbox" data-brokerId = "assign-${data.quote_broker_u_id}" id="assign-${data.quote_broker_u_id}-${data.qrp_id}-${(data.qrp_payment_status).replace(" ","_")}" ><span class="checkboxmark"></span></label></span></div>`;
             
            }
          },
          {
            data: null,
            render: function (data) {
                return `<a href="Javascript:void(0)" id="${data.quote_broker_u_id}" class="clickRowU" style="text-decoration: none"><b>${data.quote_broker_id}</b></a>`;
            }
          }, 
          {
            data: null,
            render: function (data) {
              if (data.qu_type == 1 || data.qu_type == 6) {
                return `<a href="Javascript:void(0)" id="${data.qu_id}-${data.qu_qus_id}-${data.qu_type}" class="clickRowQ" style="text-decoration: none"><b>${data.qu_id}</b></a>`;
              } else {
                return `<a href="Javascript:void(0)" id="${data.qu_display_state}-${data.qu_qus_id}-${data.qu_type}" class="clickRowQ" style="text-decoration: none"><b>${data.qu_id}</b></a>`;
              }
            }
          },
          {
            'data' : 'quote_effective_date_format' 
          },
          {
            'data' : 'qu_policy_number' 
          },
          {
            'data' : 'qu_insured_name' 
          },
          {
            'data' : 'qrp_payment_type' 
          },
          {
            'data' : 'qrp_payment_status' 
          }, 
          {
            'data' : 'quote_premium' 
          }, 
          {
            'data' : 'qrp_net_due' 
          }, 
          {
            'data' : 'qrp_deposit' 
          }, 
          {
            'data' : 'qrp_deposit_due_date' 
          }, 
          {
            'data' : 'qrp_deposit_paid_date' 
          }, 
          {
            'data' : 'qrp_balance' 
          }, 
          {
            'data' : 'qrp_balance_due_date' 
          },
        ],  
        'columnDefs': [
          {
            'targets': [ 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12,13,14],
            'searchable': false
          },
          {
          'orderable': false,
          'className': 'select-checkbox',
          'targets': 0
          }
        ],
        order: [[14, "ASC"]]
      });
      $('.dataTables_filter input').unbind();

      $('.dataTables_filter input').bind('blur change', function (e) {
    
        if ($(this).val() != "") {
          self.quotesTable.search($(this).val()).draw();
        }
        else {
          self.quotesTable.search('').draw();
        }

     
      });

      $(document).on('click', '.clickRowQ', ($event) => {
        let quoteIds = $($event)[0].currentTarget.id;
        let splitIds = quoteIds.split('-');
        self.goToCompany(splitIds[0], splitIds[1], splitIds[2]);
      });
      $(document).on('click', '.clickRowU', ($event) => {
        localStorage.setItem('brokerDispName', $($event)[0].currentTarget.text);
        self.brokerId = $($event)[0].currentTarget.id;
        self.goToBrokerQuotes();
      });
   
      let checkedArray = [];
      $(document).on('click', 'input[id ^= "assign-"]', () => {
        $('input[id ^= "assign-"]:checked').map(function () {
          var splitType = (this.id).toString().split('-');
          checkedArray.push(splitType[1]);
        }).get();
    
        if (checkedArray.length != self.allpaymenylist.list.length) {
          $('#allBrokersCheckBox').prop('checked', false);
        }
      });
    
      $(document).on('click', 'input[id = "allBrokersCheckBox"]', ($event) => {
        if ($event.currentTarget.checked == true) {
          for (let index = 0; index < self.allpaymenylist.list.length; index++) {
            const element = self.allpaymenylist.list[index];
            $('#assign-' + element.quote_broker_u_id+'-'+element.qrp_id+'-'+(element.qrp_payment_status).replace(" ","_")).prop('checked', true);
          }
        }
        else {
          for (let index = 0; index < self.allpaymenylist.list.length; index++) {
            const element = self.allpaymenylist.list[index];
            $('#assign-' + element.quote_broker_u_id+'-'+element.qrp_id+'-'+(element.qrp_payment_status).replace(" ","_")).prop('checked', false);
          }
        }
      });
    });
    self.spinner.hide();
  }
  goToBrokerQuotes() { 
    localStorage.setItem('insuredAutoSave', '0');
    localStorage.setItem('insuredTabForm', JSON.stringify({}));

    localStorage.setItem('brokerViewId', this.brokerId);
  
    if(localStorage.getItem('wholesalebroker') == '1' || localStorage.getItem('checkDisplayQuotes') == '1'){
      this.router.navigate(['/wbroker-quotes']);
    }else{
      this.router.navigate(['/broker-quotes']);
    }
  }

  closeEmailPopUp() {
    this.modalService.close('notification-email-popup');
  }

 
  openNotificationPopUp() {
    var allCheckedValuess = [];
    var allreportIdarr =[];
   
    $('input[id ^= "assign-"]:checked').map(function () {
      var splitType = (this.id).toString().split('-');
      allCheckedValuess.push(splitType[1]);
      allreportIdarr.push(splitType[1]+'-'+splitType[2]+'-'+(splitType[3]).replace("_"," "));
    }).get();

    if (allCheckedValuess.length == 0) {
      this.modalService.open('assign-admins-message');
    }
    else {
      this.spinner.show();
      this.modalService.open('notification-email-popup');
      this.allCheckedValues = allCheckedValuess;
      this.allreportIdarrs = allreportIdarr;
      
      this.userService.getBrokerEmailsAndTemplate(this.allCheckedValues,2).subscribe(data => {
        this.submitted = false;
        this.sendMailForm.reset();
        
        this.spinner.hide();
        if (data.templates) {
          this.sendMailForm.patchValue({
            mail_to_u_id: data.allUserUserIds,
            mail_to: data.allUserEmails,
            mail_tmp:''
            
          });
        }
      });

    }
  }
  
  get f() { return this.sendMailForm.controls; }
  onSelectCCEmails(){
    let ccEmails = this.sendMailForm.value.mail_cc;
    this.isValidccEmail = true;
    if (ccEmails != "") {
      var removeSpaceEmails = ccEmails.replace(/\s/g, '');
      var emailsArray = removeSpaceEmails.split(',');
      emailsArray.forEach(emailId => {
        if (!this.validateEmail(emailId)) {
          this.isValidccEmail = false;
        }
      });
    } 
  }
    
  validateEmail(email: any) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }
  onReady($event) {
    $event.plugins.get('FileRepository').createUploadAdapter = (loader) => {
      return new MyUploadAdapter(loader);
    };
  }

  closeMessagePop() {
    this.modalService.close('assign-admins-message');
  }

  SendEmails() {
    this.submitted = true;
    if (this.sendMailForm.invalid) {
      return;
    }
    if(!this.isValidccEmail){
      document.querySelector('#emailCC').scrollIntoView({ behavior: 'smooth', block: 'center' });
    
      return;
    }
    var trimmednumber = this.sendMailForm.value.mail_msg.replace(/[^A-Za-z]/g, "");
    if ((trimmednumber).length < 20) {
      this.messageBodyValidation = true;
      return;
    }
    this.messageBodyValidation = false;
    var domain = location.hostname + (location.port ? ':' + location.port : '');
    var postData = {};
    postData = this.sendMailForm.value;
    postData['domain'] = domain;
    postData['payments']=this.allreportIdarrs;
    this.spinner.show();
    this.userService.sendPayNotificationMailToBrokers(postData).subscribe(data => {
      this.spinner.hide();
      this.modalService.close('notification-email-popup');

      this.tableShow = 0;
      this.quotesTable.destroy();
      let self = this;
      setTimeout(() => {
        self.ngOnInit();
      });

 

    });

    $('#search_value').val(''); 
    localStorage.setItem('searchvalue',''); 
  }
  goToCompany(quoteId: any, quoteStatus: any, quoteType: any) {
    
    localStorage.removeItem('isLroQuoteNew') 
   localStorage.removeItem('isNewLro');  

    localStorage.setItem('insuredAutoSave', '0');
    localStorage.setItem('insuredTabForm', JSON.stringify({}));
    localStorage.setItem('quoteStatus', quoteStatus);
    localStorage.setItem('qu_id', quoteId);
    localStorage.setItem('qu_type', quoteType);
    if( quoteStatus != 1 && quoteStatus != 2   && quoteStatus != 3   && quoteStatus != 4   && quoteStatus != 5   && quoteStatus != 6   && quoteStatus != 7   && quoteStatus != 8   && quoteStatus != 16   && quoteStatus != 18   && quoteStatus != 19   && quoteStatus != 20   && quoteStatus != 21   && quoteStatus != 25   && quoteStatus != 26   && quoteStatus != 27   && quoteStatus != 28   && quoteStatus != 29   && quoteStatus != 31   && quoteStatus != 34   && quoteStatus != 35   && quoteStatus != 36  && this.loggedUserRole  != '3' && ( quoteType != 5 && quoteType != 8  && quoteType != 10)){
     
      localStorage.setItem('recordPayment_Status', quoteStatus);
    }else{
      localStorage.setItem('recordPayment_Status', '0');
    }
    
    if(quoteType == 5){
      localStorage.setItem('wc_type_quote', '1');
    }
    if (quoteStatus == 7 && quoteType == 5) {
      localStorage.setItem('isNewExcessWithWC', '0');
      localStorage.setItem('new_quote_tab', '0');
      this.dataService.changecurrentBreadcrumQuote(quoteId);
      localStorage.setItem('new_wc_quote_tab', '1');
      localStorage.setItem('new_wc_quoteStatus', '1');
      if (this.loggedUserRole == '2' || this.loggedUserRole == '1') 
      {
        localStorage.setItem('save_admin_log', '1');
      }
      
      this.router.navigate(['/quote-summary', quoteId]);
    }else  if (quoteType == 10) {
      localStorage.setItem('new_quote_id', '1');
      localStorage.setItem('new_quote_tab', '1');
      localStorage.setItem('new_co_quoteStatus', '1');
      localStorage.setItem('isNewCO', '0');
      localStorage.setItem('theQuoteType', 'cppl');
      localStorage.removeItem('isNewExcessWithWC');
      this.dataService.changecurrentBreadcrumQuote(quoteId);
   

      if (this.loggedUserRole == '2' || this.loggedUserRole == '1') 
      {
        localStorage.setItem('save_admin_log', '1');
      }

      this.router.navigate(['/eo-quote-summary', quoteId]);
    }else if(quoteType == 11){
      localStorage.setItem('new_quote_id', '1');

      localStorage.setItem('isNewErma', '0');

      localStorage.setItem('theQuoteType', 'erma');
      localStorage.removeItem('isNewExcessWithWC');
      this.dataService.changecurrentBreadcrumQuote(quoteId);
      if (this.loggedUserRole == '2' || this.loggedUserRole == '1') {
        localStorage.setItem('save_admin_log', '1');
      }
      this.router.navigate(['/erma-quote-summary', quoteId]);
    } else if (quoteType == 9) {
        localStorage.setItem('new_quote_id', '1');
        localStorage.setItem('new_quote_tab', '1');
        localStorage.setItem('new_br_quoteStatus', '1');
        localStorage.setItem('isNewBr', '0');
        this.dataService.changecurrentBreadcrumQuote(quoteId);
      

        if (this.loggedUserRole == '2' || this.loggedUserRole == '1') 
        {
            localStorage.setItem('save_admin_log', '1');
        }

        this.router.navigate(['/builders-risk-quote-summary', quoteId]);
    }else if(quoteType == 13){
      localStorage.setItem('new_quote_id', '1');
       localStorage.setItem('new_quote_tab', '1');
      localStorage.setItem('isNewLro', '0');
 
      localStorage.setItem('theQuoteType', 'LRO');
      localStorage.removeItem('isNewExcessWithWC');
      this.dataService.changecurrentBreadcrumQuote(quoteId);
      if (this.loggedUserRole == '2' || this.loggedUserRole == '1') {
        localStorage.setItem('save_admin_log', '1');
      }
      this.router.navigate(['/non-contractors-gl-quote-summary', quoteId]);
    } else if(quoteType == 8) {
        localStorage.setItem('new_quote_id', '1');
        localStorage.setItem('new_quote_tab', '1');
        localStorage.setItem('theQuoteType', 'imu');
        this.dataService.changecurrentBreadcrumQuote(quoteId);
        if (this.loggedUserRole == '2' || this.loggedUserRole == '1') {
            localStorage.setItem('save_admin_log', '1');
        }
        this.router.navigate(['/quote-summary', quoteId]);
    } else if (quoteStatus == 7 || quoteStatus== 34 || quoteStatus== 3) {
        localStorage.setItem('new_quote_id', '1');
        localStorage.setItem('new_quote_tab', '1');
        this.dataService.changecurrentBreadcrumQuote(quoteId);
        localStorage.setItem('new_wc_com_quoteStatus', '1');

        if (this.loggedUserRole == '2' || this.loggedUserRole == '1') 
        {
          localStorage.setItem('save_admin_log', '1');
        }

        this.router.navigate(['/quote-summary', quoteId]);
    } else {
        localStorage.setItem('new_quote_id', '2');
        localStorage.setItem('new_quote_tab', '0');
        localStorage.setItem('new_wc_quoteStatus', '0');
        localStorage.setItem('new_wc_com_quoteStatus', '0');
        this.dataService.changecurrentBreadcrumQuote(quoteId);

        if (this.loggedUserRole == '2' || this.loggedUserRole == '1') 
        {
          localStorage.setItem('save_admin_log', '1');
        }
         if(quoteType == 9){
           this.router.navigate(['/quote-summary', quoteId]);
        }else{
             this.router.navigate(['/quote-summary', quoteId]);
         }
      
        
    }
  }
  displayTemplate1() {
    var tempIndex = this.sendMailForm.value.mail_tmp;
    this.sendMailForm.patchValue({
      mail_sub:this.notificationTemplates[tempIndex].et_sub,
      mail_msg: this.notificationTemplates[tempIndex].et_body
    });
  }
}
