<div class="hold-transition skin-blue layout-top-nav">
    <div class="wrapper">
        <footer class="main-footer"*ngIf="domainname == '' || domainname == 'unetins'" >
            <div class="f_bgc">
                <div class="container">
                    <div class="row text-center">
                        <div class="col-md-4">
                            <img class="header_logo" *ngIf=" logopath != '' " src="{{ logopath }}">
                        </div>
                        <div class="col-md-3">
                            <img  src="assets/images/phone_n.png?v=4563" style="margin-top:20px;">
                            
                        </div>
                        <div class="col-md-3">
                            <img src="assets/images/social_icons.png">
                        </div>
                    </div>
                    <div class="footer_bread text-center">
                        <ol class="breadcrumb">
                 
                            <li  class="active">Copyright © {{year}} Shield Commercial Insurance Services, Inc.</li>
                        </ol>
                    </div>
                    <p  class="text-center" style="color:#fff">Shield Commercial Insurance
                        Services, Inc. 43-725 Monterey
                        Ave,
                        Ste A. Palm Desert, CA 92260
                        <br>Tel: 760-345-9029 Fax: 800-345-4851 CA License Number: 0E67754
                    </p>
                    <div class="row">
                        <div class="container-fluid text-center">
                            <button type="button" class="btn btn-sm btn-danger" style="padding-left: 20px; padding-right: 20px; margin-right: 5px;" (click)="openDoc(1)">Privacy Policy</button>
                            <button type="button" class="btn btn-sm btn-danger" style="padding-left: 20px; padding-right: 20px; margin-left: 5px;" (click)="openDoc(2)">Privacy Terms</button>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
        <footer *ngIf="domainname == 'normandy'">
            <div class="footer-top">
                <div class="footer-wrap text-center">
                    <h3 style="width:150px">Get in Touch</h3>
                    <p style="width:180px"><strong>Call: </strong><a href="tel:+18666886442">866-688-6442</a></p>
                    <p style="width:270px"><strong>Email:</strong> <a
                            href="mailto:info@normandyins.com?subject=Information&nbsp;about&nbsp;Normandy" >info@normandyins.com</a>
                    </p>
                </div>
            </div>
            <div class="footer-main">
                <div class="footer-wrap text-center">
                    <div class="col-4">
                        <p><a href="https://www.normandyins.com/contact-us.html"    target="_blank" >Contact</a><br>
                            <a href="https://www.normandyins.com/about-us.html"     target="_blank" >About Us</a><br>
                            <a href="https://www.normandyins.com/our-mission.html"  target="_blank" >Our Mission</a><br>
                            <a href="https://www.normandyins.com/press-release.html"target="_blank" >Press Release</a></p>
                    </div>
                    <div class="col-4">
                        <h3>Follow Us!</h3><br>
                        <a href="https://www.facebook.com/normandyins/" target="_blank"><img class="social facebook"
                                src="https://www.normandyins.com/images/facebook.svg" alt="Follow us on Facebook"></a>
                        <a href="https://twitter.com/NormandyIns" target="_blank"><img class="social twitter"
                                src="https://www.normandyins.com/images/twitter.svg" alt="Follow us on Twitter"></a>
                        <a href="https://www.linkedin.com/company/normandy-harbor-insurance-company" target="_blank"><img
                                class="social linkedin" src="https://www.normandyins.com/images/linkedin.svg"
                                alt="Follow us on Linkedin"></a><br>
                    </div>
                    <div class="col-4">
                        <h3>Agents</h3>
                        <p><a target="_blank" href="https://nhicph.jw-filehandler.com/app.html">Download our App!</a></p>
                        <a href="https://itunes.apple.com/us/app/normandy-calculator/id1108416014?mt=8" target="_blank"><img
                                class="app-button" src="https://www.normandyins.com/images/App-Store-Badge.svg"
                                alt="Download on the App Store"></a>
                        <a href="https://play.google.com/store/apps/details?id=com.normandyins.mobile.calculator"
                            target="_blank"><img class="app-button"
                                src="https://www.normandyins.com/images/google-play-badge.svg"
                                alt="Get it on Google Play"></a><br>
                    </div>
                    <div class="col-4">
                        <p>Normandy Insurance Services<br>
                            Copyright ©
                            <script
                                type="text/javascript">var today = new Date(); var year = today.getFullYear(); document.write(year);</script>
                                {{year}}.&nbsp;&nbsp;All Rights Reserved<br>
                            <a class="small-text" href="https://www.normandyins.com/sitemap.html" target="_blank">Sitemap</a> | <a
                                class="small-text" href="https://www.normandyins.com/terms-and-conditions.html">Terms &amp;
                                Conditions</a> | <a class="small-text"
                                href="https://www.normandyins.com/privacy-policy.html">Privacy Policy</a><br>
                            <a class="small-text" href="http://focalpointgraphics.com/" target="_blank">Focal Point Graphics</a>
                        </p>
                    </div>
                </div>
            </div>
        </footer>
        <footer *ngIf="domainname == 'canopy'">
            <div class="footer-top">
                <div class="footer-wrap text-center">
                    <h3 style="width:150px">Get in Touch</h3>
                   
                    <p style="width:270px"><strong>Email:</strong> <a
                            href="mailto:dcross@canopyspecialty.com?subject=Information&nbsp;about&nbsp;Canopy" > dcross@canopyspecialty.com</a>
                    </p>
                </div>
            </div>
            <div class="footer-main">
                <div class="footer-wrap text-center">
                    <div class="col-4">
                     <p>
                              
                            <a href="https://www.canopyspecialty.com/about-us"     target="_blank" >About Us</a><br>
                            <a  target="_blank" href="https://www.canopyspecialty.com/opportunities">Opportunities</a><br>
                            <a   target="_blank" href="https://www.canopyspecialty.com/services"
                                target="_blank">Services</a><br>
                       
                    </div>
                   
                    <div class="col-4">
                        <p>Canopy Specialty Insurance LLC<br>
                            Copyright ©
                            <script
                                type="text/javascript">var today = new Date(); var year = today.getFullYear(); document.write(year);</script>
                                {{year}}.&nbsp;&nbsp;All Rights Reserved<br>
                           
                        </p>
                    </div>
                </div>
            </div>
        </footer>
        <footer *ngIf="domainname == 'pbibins'">
            <div class="footer-top">
                <div class="footer-wrap text-center">
                    <h3 style="width:150px">Get in Touch</h3>
                  
                    <p style="width:270px"><strong>Email:</strong> <a
                            href="mailto:info@pbibinc.com?subject=Information&nbsp;about&nbsp;pbibinc" > info@pbibinc.com</a>
                    </p>
                </div>
            </div>
            <div class="footer-main" style="background-color:#080808">
                <div class="footer-wrap text-center">
                   
                        <p>
                            Copyright © 2019  
                            <script
                                type="text/javascript">var today = new Date(); var year = today.getFullYear(); document.write(year);</script>Pascal Burke Insurance POWERED BY Jaspar Technologies LLC
                          &nbsp;&nbsp;All Rights Reserved<br>
                           
                        </p>
                        <p style="line-height: 0;">Privacy Policy and Terms of Use</p>
                 
                </div>
            </div>
        </footer>
    </div>
</div>

