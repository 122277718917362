import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from '../services/data.service';

@Component({
  selector: 'app-breadcrum',
  templateUrl: './breadcrum.component.html',
  styleUrls: ['./breadcrum.component.css', '../../assets/css/bootstrap.css', '../../assets/css/default.css', '../../assets/css/AdminLTE.css', '../../assets/css/_all-skins.css', '../../assets/css/fSelect.css', '../../assets/datatables.net/dataTables.bootstrap.css']
})
export class BreadcrumComponent implements OnInit {

  activeMenu:any;
  showUrl:any;
  loggedUserName:any;
  loggedUserDName:any;
  loggedUserRole:any;
  loggedUserId:any;
  quoteId:any;
  hasRenewal = localStorage.getItem('hasRenewal');
  renewalQuotesList = localStorage.getItem('renewalQuotesList');
  expiredQuotesList = localStorage.getItem('exipredQuotesList');

  constructor(public router: Router, public dataService: DataService) { }

  ngOnInit() {
    this.loggedUserDName = localStorage.getItem('u_name');
    this.loggedUserName = localStorage.getItem('u_email');
    this.loggedUserRole = localStorage.getItem('u_ur_id');
    this.loggedUserId = localStorage.getItem('u_id');
    this.renewalQuotesList = localStorage.getItem('renewalQuotesList');
    this.expiredQuotesList = localStorage.getItem('expiredQuotesList');
    this.dataService.currentBreadcrumQuote.subscribe(status => this.quoteId = status); 
    this.dataService.currentCurrentURL.subscribe(status => this.showUrl = status);
    this.dataService.currentRenewalQuotesList.subscribe(status => this.renewalQuotesList = status);
    this.dataService.currentExpiredQuotesList.subscribe(status => this.expiredQuotesList = status);

    // console.log(this.showUrl);
  }

  goToHome() {
    // localStorage.setItem('active_tab', '1');
    // this.dataService.changeCurrentHeaderActive('1');
    // this.dataService.currentHeaderActive.subscribe(status => this.activeMenu = status);
    // this.router.navigate(['/home']);
    if (this.loggedUserName == localStorage.getItem('u_email')) {
      localStorage.setItem('active_tab', '1');
      this.dataService.changeCurrentHeaderActive('1');
      this.dataService.currentHeaderActive.subscribe(status => this.activeMenu = status);
      if (this.loggedUserRole == 1 || this.loggedUserRole == 2) {
        // this.router.navigate(['/quotes']);
        this.goToQuotes();
      } else {
        this.goToQuotes();
      }
      // this.router.navigate(['/home']);
    } else {
      localStorage.setItem('active_tab', '1');
      this.dataService.changeCurrentHeaderActive('1');
      if (typeof localStorage.getItem('u_id') != 'undefined' && localStorage.getItem('u_id') != null && localStorage.getItem('u_id') != 'null' && localStorage.getItem('u_id') != "") {
        // this.router.navigate(['/home']);
        if (this.loggedUserRole == 1 || this.loggedUserRole == 2) {
          this.router.navigate(['/quotes']);
        } else {
          this.goToQuotes();
        }
      }
    }
  }

  goToQuotes() {
    localStorage.setItem('renewalQuotesList','0');
    localStorage.setItem('expiredQuotesList','0');
    localStorage.setItem('active_tab', '2');
    this.dataService.changeCurrentHeaderActive('2');
    this.dataService.currentHeaderActive.subscribe(status => this.activeMenu = status);
    if(this.loggedUserRole == 3){
      localStorage.setItem('brokerViewId', this.loggedUserId);
      // this.router.navigate(['/broker-quotes']);
      if(localStorage.getItem('wholesalebroker') == '1' || localStorage.getItem('checkDisplayQuotes') == '1'){
        this.router.navigate(['/wbroker-quotes']);
      }else{
        this.router.navigate(['/broker-quotes']);
      }
    }else{
      this.router.navigate(['/quotes']);
    }
  }

  goToBrokers() {
    localStorage.setItem('active_tab', '3');
    this.dataService.changeCurrentHeaderActive('3');
    this.dataService.currentHeaderActive.subscribe(status => this.activeMenu = status);
    this.router.navigate(['/brokers']);
  }

  goToRenewalQuotes()
  {
    localStorage.setItem('renewalQuotesList','1');
    localStorage.setItem('expiredQuotesList','0');
    this.router.navigate(['/renewal-quotes']);
  }
  
  goToExpiredQuotes()
  {
    localStorage.setItem('renewalQuotesList','0');
    localStorage.setItem('expiredQuotesList','1');
    this.router.navigate(['/expired-quotes']);
  }

  goToClients() {
    localStorage.setItem('active_tab', '4');
    this.dataService.changeCurrentHeaderActive('4');
    this.dataService.currentHeaderActive.subscribe(status => this.activeMenu = status);
    this.router.navigate(['/clients']);
  }

  goToAdmins() {
    localStorage.setItem('active_tab', '5');
    this.dataService.changeCurrentHeaderActive('5');
    this.dataService.currentHeaderActive.subscribe(status => this.activeMenu = status);
    this.router.navigate(['/admins']);
  }

  goToQuoteClaimsTab(){
    this.router.navigate(['/quote-claims', this.quoteId]);
  }
  
  goToQuoteSummaryTab(){
    if(localStorage.getItem('theQuoteType') == 'LRO'){
      this.router.navigate(['/non-contractors-gl-quote-summary', this.quoteId]);
    }else{
      this.router.navigate(['/quote-summary', this.quoteId]);
    }
    
  }

}
