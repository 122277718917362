<div class="hold-transition skin-blue layout-top-nav">
    <div class="wrapper">
        <footer class="main-footer">
            <div class="container-fluid text-center" *ngIf="domainname == 'normandy'" >
                <div class="pull-right hidden-xs">
                    
                </div>
                <strong>Copyright © {{year}} Normandy Insurance Services</strong> All rights reserved.
            </div>
            <div class="container-fluid text-center" *ngIf="domainname == 'canopy'" >
                <div class="pull-right hidden-xs">
                    
                </div>
                <strong>Copyright © {{year}} Canopy Specialty Insurance LLC</strong> All rights reserved.
            </div>
            <div class="container-fluid text-center" *ngIf="domainname == 'pbibins'" >
                <div class="pull-right hidden-xs">
                   
                </div>
                <strong>Copyright © {{year}} Pascal Burke Insurance POWERED BY Jaspar Technologies LLC.</strong> All rights reserved.Privacy Policy and Terms of Use.
            </div>
            <div class="container-fluid text-center" *ngIf="domainname == ''" >
                <div class="pull-right hidden-xs">
                    
                </div>
                <strong >Copyright © {{year}} Shield Commercial Insurance Services, Inc.</strong> All rights reserved.
                <div class="row">
                    <div class="container-fluid text-center">
                        <button type="button" class="btn btn-sm btn-info" style="padding-left: 20px; padding-right: 20px;" (click)="openDoc(1)">Privacy Policy</button>
                        <button type="button" class="btn btn-sm btn-info" style="padding-left: 20px; padding-right: 20px;" (click)="openDoc(2)">Privacy Terms</button>
                    </div>
                </div>
            </div>
        </footer>
    </div>
</div>