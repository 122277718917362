import { Component, HostListener, OnInit } from '@angular/core';
import { LocationStrategy, PlatformLocation } from '@angular/common';
import { QuoteUnderwritingService } from './services/quote-underwriting.service';
import { DataService } from './services/data.service';
import { UserService } from './services/user.service';
import { Router, NavigationEnd } from '@angular/router';
import { ModalService } from './_modal'
import { NgxSpinnerService } from 'ngx-spinner';

import { ConnectionService } from 'ng-connection-service';
import { ValidationMessages } from './validation-messages';
import { UserIdleService } from 'angular-user-idle';
import { Observable, interval  } from 'rxjs';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css', '../assets/css/bootstrap.css', '../assets/css/default.css', '../assets/css/AdminLTE.css', '../assets/css/_all-skins.css', '../assets/datatables.net/dataTables.bootstrap.css']
})
export class AppComponent implements OnInit {
  status = 'ONLINE';
  isConnected = true;
  isNetworkConnection = true;

  chatGSubscription: any;
  currentGFbMessages: any;
  deleteUserdata: any;
  hNotifications: any = [];
  unreadCount = '0';
  activeMenu: any;
  loggedStatus: any;

  loginUserName: any;
  loginPassword: any;
  loginRemberMe: any;
  loaderImagePath:any;
  loaderImageClass:any;
  favIcon: HTMLLinkElement = document.querySelector('#appIcon');

  isPopState = false;
  wholesalerInfoArray: any = '';
  constructor( private connectionService: ConnectionService, public quService: QuoteUnderwritingService, public dataService: DataService, public userService: UserService, public router: Router, public location: PlatformLocation, public modalService: ModalService, private spinner: NgxSpinnerService, private userIdle: UserIdleService, private locStrat: LocationStrategy) {
    let currentPage = window.location.href;
    let currentPageSplit = currentPage.split('/');
    if (currentPageSplit[3]) {
      if (currentPageSplit[3] != "" && (currentPageSplit[3] == 'audits' || currentPageSplit[3] == 'claims' || currentPageSplit[3] == 'inspections')) {
        localStorage.setItem('previous_page', currentPageSplit[3] + '/' + currentPageSplit[4]);
      }
      if (currentPageSplit[3] == "" || currentPageSplit[3] == "home" || currentPageSplit[3] == "signup" || currentPageSplit[3] == "faqs" || currentPageSplit[3] == "ourstaff" || currentPageSplit[3] == "aboutus" || currentPageSplit[3] == "resetpassword") {
        localStorage.setItem('active_tab', '1');
        this.dataService.changeCurrentHeaderActive('1');
      }
    } else {
      localStorage.setItem('active_tab', '1');
      this.dataService.changeCurrentHeaderActive('1');
    }

    this.connectionService.monitor().subscribe(isConnected => {

      this.isConnected = isConnected;
      if (this.isConnected) {
        this.status = "ONLINE";
        this.isNetworkConnection = true;

      }
      else {
        this.status = "OFFLINE";
        this.isNetworkConnection = false;
        if (this.router.url != '/home') {
          this.modalService.open('user-check-network-information');
        }

      }
    });
  }

  ngOnInit() { 
    this.locStrat.onPopState(() => {
      this.isPopState = true;
    });
    
    let isUserLogged = localStorage.getItem('u_id');
    if(!isUserLogged){
      var domain = location.hostname + (location.port ? ':' + location.port : '');
      this.userService.getWholesalerInfo(domain).subscribe(data => {
        this.wholesalerInfoArray = data;
      });
    }
  
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd && !this.isPopState) {
      window.scrollTo(0, 0);
      this.isPopState = false;
      }
      
      if (event instanceof NavigationEnd) {
      this.isPopState = false;
      }
    });

    this.loginUserName = localStorage.getItem('loginUserName');
    this.loginPassword = localStorage.getItem('loginPassword');
    this.loginRemberMe = localStorage.getItem('loginRememberme');
    var domain = location.hostname + (location.port ? ':' + location.port : '');
    if (domain == ValidationMessages.stagingNormandyDomain || domain == ValidationMessages.productionNormandyDomain || domain == ValidationMessages.localNormandyDomain || domain == ValidationMessages.stagingCanopyDomain  || domain == ValidationMessages.productionCanopyDomain || domain == ValidationMessages.stagingPbibinsDomain  || domain == ValidationMessages.productionPbibinsDomain || domain == ValidationMessages.localNormandyDomain ) {
     this.loaderImagePath = 'assets/images/normandy_loader.gif';
     this.loaderImageClass = "loader_spinner_normandy";
    }
    else{
      this.loaderImagePath = 'assets/images/loading_gif.gif';
      this.loaderImageClass = "loader_spinner";
    }

    if (domain == ValidationMessages.stagingNormandyDomain || domain == ValidationMessages.productionNormandyDomain || domain == ValidationMessages.localNormandyDomain) 
    {
      this.favIcon.href = 'assets/images/favicon.png';
      document.getElementById("appTitle").innerHTML = "Normandy Insurance Company | A Workers' Compensation Carrier";
     }
     else if (domain == ValidationMessages.stagingCanopyDomain  || domain == ValidationMessages.productionCanopyDomain) 
     {
      this.favIcon.href = 'assets/images/canopy_favicon.png';
      document.getElementById("appTitle").innerHTML = "Canopy Specialty Insurance LLC";
     }
     else if (domain == ValidationMessages.stagingPbibinsDomain  || domain == ValidationMessages.productionPbibinsDomain ) 
     {                             
      this.favIcon.href = 'assets/images/pbib_favicon.png';
      document.getElementById("appTitle").innerHTML = "Pascal Burke Insurance Brokerage Inc. | Insurance Quotes | Sacramento, CA";
     }
     else
     {
      this.favIcon.href = 'favicon.ico';
      document.getElementById("appTitle").innerHTML = "Insurance Rater";
     }

    this.dataService.currentLoggedStatus.subscribe(status => this.loggedStatus = status);
    this.dataService.currentHeaderActive.subscribe(status => this.activeMenu = status);
    this.userIdle.startWatching();
    this.userIdle.onTimerStart().subscribe(count => {
      // console.log(count);
    });
    this.userIdle.onTimeout().subscribe(() => {
      this.spinner.show();
      localStorage.clear();
      setTimeout(() => {
        localStorage.setItem('loginUserName', this.loginUserName);
        localStorage.setItem('loginPassword', this.loginPassword);
        localStorage.setItem('loginRememberme', this.loginRemberMe);
      }, 200);
      localStorage.setItem('active_tab', '1');
      this.dataService.changeCurrentHeaderActive('1');
      this.dataService.currentHeaderActive.subscribe(status => this.activeMenu = status);
      this.dataService.changeCurrentLoggedStatus('0');
      this.dataService.currentLoggedStatus.subscribe(status => this.loggedStatus = status);
      this.spinner.hide();
      this.router.navigate(['/home']);
      this.userIdle.resetTimer();
    });
    //End
  }
  @HostListener('window:popstate', ['$event'])
  onBrowserBackBtnClose(event: Event) {
    $('jw-modal').attr('style', 'display:none');
    this.modalService.close('user-hidden-confirmation');
  }
  deleteConfirmation() {
    this.spinner.show();
    this.modalService.close('user-delete-confirmation');
    this.modalService.close('user-hidden-confirmation');
    localStorage.clear();
    setTimeout(() => {
      localStorage.setItem('loginUserName', this.loginUserName);
      localStorage.setItem('loginPassword', this.loginPassword);
      localStorage.setItem('loginRememberme', this.loginRemberMe);
    }, 200);
    localStorage.setItem('active_tab', '1');
    this.dataService.changeCurrentHeaderActive('1');
    this.dataService.currentHeaderActive.subscribe(status => this.activeMenu = status);
    this.dataService.changeCurrentLoggedStatus('0');
    this.dataService.currentLoggedStatus.subscribe(status => this.loggedStatus = status);
   
    this.spinner.hide();
    this.router.navigate(['/home']);
  }

  networkInformation() {
    this.modalService.close('user-check-network-information');
    this.logoutConfirm();
  }

  logoutConfirm() {
    this.spinner.show();
    this.loginUserName = localStorage.getItem('loginUserName');
    this.loginPassword = localStorage.getItem('loginPassword');
    this.loginRemberMe = localStorage.getItem('loginRememberme');
    localStorage.clear();
    localStorage.setItem('active_tab', '1');
    setTimeout(() => {
      localStorage.setItem('loginUserName', this.loginUserName);
      localStorage.setItem('loginPassword', this.loginPassword);
      localStorage.setItem('loginRememberme', this.loginRemberMe);
    }, 200);
    this.dataService.changeCurrentHeaderActive('1');
    this.dataService.currentHeaderActive.subscribe(status => this.activeMenu = status);
    this.dataService.changeCurrentLoggedStatus('0');
    this.dataService.currentLoggedStatus.subscribe(status => this.loggedStatus = status);
    this.spinner.hide();
    this.router.navigate(['/home']);
  }

  @HostListener('window:popstate', ['$event'])
  onClick(event: Event) {

    this.dataService.returnbackurl=1;
  }
}
