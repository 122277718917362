<div class="modal fade" id="forgotPass" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" data-backdrop="static" data-keyboard="false">
        <div class="modal-dialog login_modal_w" role="document">
          <div class="modal-content">
            <div class="modal-header login_header_bg">
              <button type="button" class="close" data-dismiss="modal" aria-label="Close" id="closeForgotId" (click)="resetFFormValues()"><span aria-hidden="true">&times;</span></button>
              <h4 class="modal-title text-center" id="myModalLabel">Forgot Password</h4>
            </div>
            <div class="modal-body" *ngIf="showDivId == 2">
                <p text-center style="color:green">Please check your email, Reset password link is sent.</p>
                <div class="form-group login_btn">
                    <button type="button" class="btn btn-primary btn-block" (click)="resetFFFormValues()">Ok</button>
                </div>
            </div>
            <div class="modal-body" *ngIf="showDivId == 1">
                <form [formGroup]="forgotForm">
                    <div class="form-group">
                        <label for="exampleInputEmail1">Email ID / Producer ID</label>
                        <input type="email" class="form-control" formControlName="u_email" (keyup)="disableFooterMsg()" (keyup.enter)="saveLoginInfo()">
                        <div *ngIf="submitted && f.u_email.errors" class="invalid-feedback">
                            <div *ngIf="f.u_email.errors.required" style="color:red;font-size: 15px;">Email is required</div>
                            <div *ngIf="f.u_email.errors.email" style="color:red;font-size: 15px;">Email must be a valid email address</div>
                        </div>
                    </div>
                    <p text-center style="color:red" [hidden]="!forgotError">Entered email is not registered OR not in active state.</p>
                    <div class="form-group login_btn">
                        <button type="button" class="btn btn-primary btn-block" (click)="saveLoginInfo()">Send</button>
                    </div>
                    <div class="row">
                        <div class="col-md-7 text-left">
                            <small>Don’t have an account? <a href="javascript:void(0)" (click)="goToSignUp()">Sign Up</a></small>
                        </div>
                        <div class="col-md-5 text-right">
                            <small><a href="javascript:void(0)" (click)="openLoginModal()">Login</a></small>
                        </div>
                    </div>
                </form>
            </div>
        </div>
      </div>
    </div>