import { Injectable } from '@angular/core';
import { ValidationMessages } from '../validation-messages';

@Injectable({
    providedIn: 'root'
})
export class HelperService {

    constructor() { }

    public getApiUrl() {
        var environmentt: string;
        var appUrl: string;

        environmentt = window.location.hostname;
        switch (environmentt) {
            case 'localhost':
                appUrl = 'http://localhost:8000/api';
                break;
            case (ValidationMessages.productionUrl):
                appUrl = 'https://' + ValidationMessages.productionAPUrl + '/api';
                break;
            case (ValidationMessages.productionUrlWithWww):
                appUrl = 'https://' + ValidationMessages.productionAPUrl + '/api';
                break;
            case (ValidationMessages.productionNormandyDomain):
                appUrl = 'https://' + ValidationMessages.productionAPUrl + '/api';
                break;
            case (ValidationMessages.productionCanopyDomain):
                appUrl = 'https://' + ValidationMessages.productionAPUrl + '/api';
                break;
            case (ValidationMessages.productionPbibinsDomain):
                appUrl = 'https://' + ValidationMessages.productionAPUrl + '/api';
                break; 
            default:
                appUrl = ValidationMessages.stagingUrl + '/api';
        }
        return appUrl;
    }

    public getViewformEndpoint(){
        var appUrl: string;
        var environmentt: string;

        environmentt = window.location.hostname;

        switch (environmentt) {
            case 'localhost':
                // appUrl = 'https://shield-uploads-bucket.s3.us-east-2.amazonaws.com/shield-view-form-pdfs/';
                appUrl = 'https://stage-shield-uploads.s3.amazonaws.com/shield-view-form-pdfs/';
                break;
            case (ValidationMessages.productionUrl):
                appUrl = 'https://shield-prod-uploads.s3.us-west-1.amazonaws.com/shield-view-form-pdfs/';
                break;
            case (ValidationMessages.productionUrlWithWww):
                appUrl = 'https://shield-prod-uploads.s3.us-west-1.amazonaws.com/shield-view-form-pdfs/';
                break;
            case (ValidationMessages.productionNormandyDomain):
                appUrl = 'https://shield-prod-uploads.s3.us-west-1.amazonaws.com/shield-view-form-pdfs/';
                break;
            case (ValidationMessages.productionCanopyDomain):
                appUrl = 'https://shield-prod-uploads.s3.us-west-1.amazonaws.com/shield-view-form-pdfs/';
                break;
            case (ValidationMessages.productionPbibinsDomain):
                appUrl = 'https://shield-prod-uploads.s3.us-west-1.amazonaws.com/shield-view-form-pdfs/';
                break;
            default:
                // appUrl = 'https://shield-uploads-bucket.s3.us-east-2.amazonaws.com/shield-view-form-pdfs/';
                appUrl = 'https://stage-shield-uploads.s3.amazonaws.com/shield-view-form-pdfs/';
        }
        
        return appUrl;
    }
}
