import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormBuilder, Validators, UntypedFormGroup } from '@angular/forms';
import { UserService } from '../services/user.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';

@Component({
  selector: 'app-forgotpassword',
  templateUrl: './forgotpassword.component.html',
  styleUrls: ['./forgotpassword.component.css', '../../assets/fcss/bootstrap.css', '../../assets/fcss/default.css', '../../assets/fcss/AdminLTE.css', '../../assets/fcss/_all-skins.css']
})
export class ForgotpasswordComponent implements OnInit {

  forgotForm: UntypedFormGroup;
  submitted = false;
  forgotError = false;
  showDivId = 1;
  constructor(public router: Router, private formBuilder: UntypedFormBuilder, public userService:UserService, private spinner: NgxSpinnerService) { }

  goToSignUp(){
    this.showDivId = 1;
    this.forgotError = false;
    this.submitted = false;
    this.forgotForm.reset();
    document.getElementById('closeForgotId').click();
    this.router.navigate(['/signup']);
  }

  openLoginModal(){
    this.showDivId = 1;
    this.forgotError = false;
    this.submitted = false;
    this.forgotForm.reset();
    document.getElementById('closeForgotId').click();
    document.getElementById('loginModalId').click();
  }

  resetFFormValues() {
    this.showDivId = 1;
    this.forgotError = false;
    this.submitted = false;
    this.forgotForm.reset();
  }

  resetFFFormValues() {
    document.getElementById('closeForgotId').click();
  }

  disableFooterMsg(){
    this.forgotError = false;
  }

  ngOnInit() {
    this.showDivId = 1;
    this.forgotForm = this.formBuilder.group({
      // u_email: ['', [Validators.required, Validators.email]]
      u_email: ['', Validators.required]
    });
  }

  get f() { return this.forgotForm.controls; }

  saveLoginInfo(){
    this.forgotError = false;
    this.submitted = true;
    if (this.forgotForm.invalid) {
      return;
    }
    var postData = {};
    postData = this.forgotForm.value;
    postData['type'] = 4;
    postData['url'] =  location.protocol+'//'+location.hostname+(location.port ? ':'+location.port : '');
    this.spinner.show();
    this.userService.userOperations(postData).subscribe(data => {
      this.checkUserData(data);
    });
  }

  checkUserData(userInfo:any){
    if(userInfo.output == 0){
      this.forgotError = true;
    }else{
      this.showDivId = 2;
    }
    this.spinner.hide();
  }

}
