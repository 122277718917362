import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';

@Injectable()
export class DataService {
   returnbackurl :any =0;
  private renewalQuotesList = new BehaviorSubject('0');
  private expiredQuotesList = new BehaviorSubject('0');
  private currentURL = new BehaviorSubject('/');
  private unreadNCount = new BehaviorSubject('0');
  private notifications = new BehaviorSubject([]);
  private headerActive = new BehaviorSubject('1');
  private headerDisplayName = new BehaviorSubject('');
  private loggedStatus = new BehaviorSubject('0');
  private breadcrumQuote = new BehaviorSubject('New quote');
  currentRenewalQuotesList = this.renewalQuotesList.asObservable();
  currentExpiredQuotesList = this.expiredQuotesList.asObservable();
  currentBreadcrumQuote = this.breadcrumQuote.asObservable();
  currentCurrentURL = this.currentURL.asObservable();
  currentUnreadNCount = this.unreadNCount.asObservable();
  currentNotifications = this.notifications.asObservable();
  currentHeaderActive = this.headerActive.asObservable();
  currentHeaderDisplayName = this.headerDisplayName.asObservable();
  currentLoggedStatus = this.loggedStatus.asObservable();
  
  constructor() {
    if (typeof localStorage.getItem('u_id') != undefined && localStorage.getItem('u_id') != null && localStorage.getItem('u_id') != "") {
      this.changeCurrentLoggedStatus('1');
    }
    if (typeof localStorage.getItem('u_email') != undefined && localStorage.getItem('u_email') != null && localStorage.getItem('u_email') != "") {
      this.changeCurrentHeaderDisplayName(localStorage.getItem('u_email'));
    }
    if (typeof localStorage.getItem('active_tab') != undefined && localStorage.getItem('active_tab') != null && localStorage.getItem('active_tab') != "") {
      this.changeCurrentHeaderActive(localStorage.getItem('active_tab'));
    }
   }

  changecurrentUnreadNCount(value: any) {
    this.unreadNCount.next(value);
  }

  changeCurrentNotifications(value: any) {
    this.notifications.next(value);
  }

  changeCurrentHeaderActive(value: any) {
    this.headerActive.next(value);
  }

  changeCurrentHeaderDisplayName(value: any) {
    this.headerDisplayName.next(value);
  }

  changeCurrentLoggedStatus(value: any) {
    this.loggedStatus.next(value);
  }

  changecurrentCurrentURL(value: any) {
    this.currentURL.next(value);
  }

  changecurrentRenewalQuotesList(value: any) {
    this.renewalQuotesList.next(value);
  }
  changecurrentExpiredQuotesList(value: any) {
    this.expiredQuotesList.next(value);
  }

  changecurrentBreadcrumQuote(value: any) {
    this.breadcrumQuote.next(value);
  }

}