<div class="hold-transition skin-blue layout-top-nav">
  <div class="wrapper">

    <div class="content-wrapper">
      <div class="container-fluid">
     
        <section class="content-header">
          <h1> Import Policies </h1>
        
        </section>

    
        <section class="content">
          <div class="box">
            <div class="box-body pos_r">
              <div class="form_heading_title">
                <form [formGroup]="uploadCsvFile" enctype="multipart/form-data">
                  <div class="row lable_fontweight">
                    <div class="col-md-3">
                      <div class="form-group" style="margin-bottom: 0px;">
                        <label>Select Quote Type</label>
                        <select class="form-control" (change)="onSelectQuoteType()" formControlName="quoteType">
                          <option value="">Select Report</option>
                          <option value="1">GL</option>
                          <option value="2">Excess</option>
                        </select>
                      </div>
                      <div *ngIf="isClickedSubmit && uploadCsvFile.controls.quoteType.errors" style="color: red;">
                        <p *ngIf="uploadCsvFile.controls.quoteType.errors.required">
                          Please select quote type
                        </p>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="form-group" style="margin-bottom: 0px;">
                        <label>Select File</label>
                        <input type="file" 
                        class="form-control"
                        #policyFile 
                        (change)="getFile($event)">
                      </div>
                      <div *ngIf="isClickedSubmit && uploadCsvFile.controls.selectedFile.errors" style="color: red;">
                        <p *ngIf="uploadCsvFile.controls.selectedFile.errors.required">
                          Please select a CSV file
                        </p>
                      </div>
                    </div>
                    <div class="col-md-3" style="margin-top: 27px;">
                      <button class="btn btn-primary" (click)="submitFile()">Upload File</button>
                    </div>
                  </div>
                </form>
              </div>
              <div class="col-sm-12" style="padding-left: 0px;">
                <p *ngIf="outputType != 4 && outputType != 5 " style="margin-bottom: 0px; margin-top: 10px; color: red;">{{ showMsg }}</p>
                <p *ngIf="outputType == 4 || outputType == 5"
                  style="margin-bottom: 0px; margin-top: 10px;" 
                  [ngClass]="noExcessQuote.length > 0 ? 'red' : 'green'">{{ showMsg }}</p>
                <div *ngIf="noExcessQuote && noExcessQuote.length > 0">
                  <span style="color: red; font-weight: bold;" *ngFor="let item of noExcessQuote; let i = index">
                    {{ item }}<span *ngIf="i != (noExcessQuote.length -1)">{{', '}}</span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</div>